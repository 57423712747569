import * as React from 'react'

import { CheckCircle, Warning } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'

import { Dialog } from '../..'
import { hasIdentityProofs } from '../../../services'
import { IdentityProofsStep } from '../identity_proofs_step'

import type { UltimateBeneficialOwner } from '../../../queries'

type UboIdentityProofCreatorProps = {
  ultimateBeneficialOwner: UltimateBeneficialOwner
  textButton?: boolean
}

export const UboIdentityProofCreator = ({
  ultimateBeneficialOwner,
  textButton,
}: UboIdentityProofCreatorProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const title = `Agregar documentos de identidad para ${ultimateBeneficialOwner.givenName} `
    + `${ultimateBeneficialOwner.familyName}`

  return (
    <React.Fragment>
      {textButton ? (
        <Button
          onClick={openDialog}
          color={hasIdentityProofs(ultimateBeneficialOwner) ? 'success' : 'warning'}
          variant='contained'
          endIcon={hasIdentityProofs(ultimateBeneficialOwner) ? <CheckCircle /> : <Warning />}
        >
          {hasIdentityProofs(ultimateBeneficialOwner) ? 'Ver documentos' : 'Agregar documentos'}
        </Button>
      ) : (
        <IconButton
          onClick={openDialog}
          edge='end'
          sx={{
            color: hasIdentityProofs(ultimateBeneficialOwner) ? 'success.main' : 'warning.main',
            '&:hover': {
              backgroundColor: 'transparent',
              color: hasIdentityProofs(ultimateBeneficialOwner) ? 'success.dark' : 'warning.dark',
            },
          }}
        >
          {hasIdentityProofs(ultimateBeneficialOwner) ? <CheckCircle /> : <Warning />}
        </IconButton>
      )}
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={title}
      >
        <IdentityProofsStep
          handleBack={closeDialog}
          handleNext={closeDialog}
          uboId={ultimateBeneficialOwner.id}
        />
      </Dialog>
    </React.Fragment>
  )
}
