import * as React from 'react'

import { Button } from '@mui/material'

import { ButtonContainer, ButtonsContainer, Dialog } from '../../../components'

const SITE_NAME = process.env.GATSBY_SITE_NAME

type FundsOriginDisclaimerProps = {
  dialogOpen: boolean
  closeDialog: () => void
  handleNext: () => void
}

export const FundsOriginDisclaimer = ({
  dialogOpen,
  closeDialog,
  handleNext,
}: FundsOriginDisclaimerProps) => (
  <Dialog
    open={dialogOpen}
    onClose={closeDialog}
    maxWidth='xs'
    title='Confirmar declaración de origen de fondos'
    contentText={(
      <React.Fragment>
        <strong>Los activos</strong> que han sido o serán abonados o depositados a cualquier
        cuenta no provienen, directa ni indirectamente, de actividades ilícitas, y dicho
        abono o depósito no constituye uno de los delitos contemplados en el artículo
        27 de la Ley 19.913, o la que la sustituya o reemplace.
        <br style={{ display: 'block', content: '""', height: '0.5em' }} />
        <strong>Los fondos</strong> no han sido y no serán abonados o depositados, directa o
        indirectamente, por o a través de las siguientes personas (las personas
        prohibidas): Terroristas u organizaciones terroristas, incluyendo aquellas
        personas o entidades indicadas en la lista en idioma inglés denominada Lista
        f Specially Designated Nationals and Blocked Persons emitida por la OFAC del
        Departamento del Tesoro del Gobierno de los Estados Unidos (Treasury Departments
        Oce of Foreign Assets Control). Banco pantalla (Banco sin presencia física).
        <br style={{ display: 'block', content: '""', height: '0.5em' }} />
        <strong>Los fondos</strong> no han sido y no serán abonados o depositados, directa o
        indirectamente, por o a través de Personas Expuestas Políticamente, o que,
        si así fuere el caso, ha entregado información al respecto a {SITE_NAME} para
        que este obtenga las correspondientes autorizaciones para operar, de
        conformidad a lo establecido en la ley N°19.913 que crea la Unidad de Análisis
        Financiero. Se entiende por Personas Expuestas Políticamente, a los individuos
        que desempeñan o han desempeñado funciones públicas destacadas en un país
        extranjero, considerando, por ejemplo, a jefes de estado o de un gobierno,
        políticos de alta jerarquía, funcionarios gubernamentales, judiciales o
        militares de alta jerarquía, altos ejecutivos de empresas estatales,
        funcionarios importantes de partidos políticos, etc., así como sus cónyuges,
        sus parientes hasta el segundo grado de consanguinidad, y las personas naturales
        con las que hayan celebrado un pacto de actuación conjunta, mediante el cual
        tengan poder de voto suficiente para influir en sociedades constituidas en Chile.
      </React.Fragment>
    )}
    contentTextProps={{ textAlign: 'justify' }}
  >
    <ButtonsContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          onClick={closeDialog}
        >
          Modificar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          color='primary'
          variant='contained'
          onClick={handleNext}
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Dialog>
)
