import * as React from 'react'

import { AccountBalance } from '@mui/icons-material'
import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material'

import { AppContainer, ButtonContainer, ButtonsContainer } from '../../components'
import { AppContext } from '../../contexts'

export const PendingRevisionStep = () => {
  const { appName } = React.useContext(AppContext)

  return (
    <Box
      display='flex'
      alignItems='center'
      flexGrow={1}
    >
      <Container
        disableGutters
        maxWidth='xs'
      >
        <AppContainer sx={{ px: 3, py: 2 }}>
          <Stack>
            <Typography
              variant='h6'
              component='p'
              textAlign='center'
              mb={2}
            >
              Tus datos de diligencia reforzada serán verificados en breve
            </Typography>
            <Divider />
            <Typography
              textAlign='center'
              mt={3}
              mb={1}
            >
              Una vez que los confirmemos te enviaremos un email para que
              comiences a usar {appName}.
            </Typography>
            <ButtonsContainer sx={{ my: 1 }}>
              <ButtonContainer xs={12}>
                <Button
                  fullWidth
                  href='/app/'
                  startIcon={<AccountBalance />}
                  variant='contained'
                >
                  Ir a Portafolio
                </Button>
              </ButtonContainer>
            </ButtonsContainer>
          </Stack>
        </AppContainer>
      </Container>
    </Box>
  )
}
