import * as React from 'react'

import { WealthOriginForm } from './wealth_origin_form'
import { Dialog } from '../../../components'
import { IdentityDisplay } from '../identity_display'

import type { UltimateBeneficialOwner } from '../../../queries'

type UboWealthOriginDisplayProps = {
  ubo: UltimateBeneficialOwner
}

export const UboWealthOriginDisplay = ({
  ubo,
}: UboWealthOriginDisplayProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <IdentityDisplay
        type='PERSON'
        name={`${ubo.givenName} ${ubo.familyName}`}
        isValid={!!ubo.wealthOrigin}
        openDialog={openDialog}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`Origen de patrimonio para ${ubo.givenName} ${ubo.familyName}`}
      >
        <WealthOriginForm
          uboId={ubo.id}
          wealthOrigin={ubo.wealthOrigin}
          isBusiness={false}
          handleBack={closeDialog}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}
