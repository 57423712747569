import * as React from 'react'

import { MenuItem, Stack } from '@mui/material'
import * as Yup from 'yup'

import { DocumentUpload } from '../components'
import { SelectField } from '../forms'
import { ONBOARDING_DOCUMENT_CONTENT_TYPES } from '../queries'
import { hasValidSize } from '../services'

const hasValidContentType = (file: File | undefined) => (
  !file || ONBOARDING_DOCUMENT_CONTENT_TYPES.includes(file.type)
)

export type OnboardingDocumentFormValues = {
  file: File | undefined
  description: string
}

export const onboardingDocumentInitialValues: OnboardingDocumentFormValues = {
  file: undefined,
  description: '',
}

export const onboardingDocumentValidationSchema = (
  Yup.object().shape({
    file: Yup.mixed()
      .required('Este campo es obligatorio')
      .test(
        'fileType',
        'Debes subir un archivo PDF/HEIC/JPG/PNG',
        (value) => hasValidContentType(value),
      )
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
    description: Yup.string()
      .required('Este campo es obligatorio'),
  })
)

export const OnboardingDocumentFields = ({ descriptions }: { descriptions: string[] }) => (
  <Stack spacing={3}>
    <SelectField
      name='description'
      label='Tipo de documento'
    >
      {descriptions.map((proof, index) => (
        <MenuItem
          key={index}
          value={proof}
        >
          {proof}
        </MenuItem>
      ))}
    </SelectField>
    <DocumentUpload
      name='file'
      imageAlt='Documento de diligencia reforzada'
      contentTypes={ONBOARDING_DOCUMENT_CONTENT_TYPES}
    />
  </Stack>
)
