import { gql } from '@apollo/client'

import { RENEWAL_FIELDS, Renewal } from './kyc_renewals'

export type AccountStatus =
  'IDENTITY_PENDING_REVIEW'
  | 'IDENTITY_PENDING_CHANGES'
  | 'PENDING_ENHANCED_DILIGENCE'
  | 'IDENTITY_REJECTED'
  | 'IDENTITY_VERIFIED'
  | 'IDENTITY_UNVERIFIED'
  | 'EMAIL_UNCONFIRMED'
  | 'UNKNOWN'

export type AccountInformationVars = Record<string, never>

export type AccountInformationData = {
  accountInformation: {
    accountStatus: AccountStatus
    email: string
    isBusiness?: boolean
    nickName: string
    legalName?: string
    legalDocumentNumber?: string
    lastVerificationUpdate: string
    pendingKycRenewals: Renewal[]
  }
}

export const ACCOUNT_INFORMATION_QUERY = gql`
  ${RENEWAL_FIELDS}
  query AccountInformation {
    accountInformation {
      accountStatus
      email
      isBusiness
      nickName
      legalName
      legalDocumentNumber
      lastVerificationUpdate
      pendingKycRenewals {
        ...RenewalFields
      }
    }
  }
`
