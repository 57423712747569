import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Info, Warning } from '@mui/icons-material'
import { Button, Divider, Stack, darken, lighten, useTheme } from '@mui/material'

import { BusinessFundsOriginDisplay } from './business_funds_origin_display'
import { FundsOriginForm } from './funds_origin_form'
import { UboFundsOriginDisplay } from './ubo_funds_origin_display'
import { ButtonContainer, ButtonsContainer, IconCard, Loading } from '../../../components'
import { FUNDS_ORIGIN_QUERY, ULTIMATE_BENEFICIAL_OWNERS_QUERY } from '../../../queries'

import type {
  FundsOrigin,
  FundsOriginData,
  FundsOriginVars,
  UltimateBeneficialOwnersData,
  UltimateBeneficialOwnersVars,
} from '../../../queries'

const SITE_NAME = process.env.GATSBY_SITE_NAME

type BusinessFundsOriginsProps = {
  fundsOrigin?: FundsOrigin
  handleBack: () => void
  handleNext: () => void
}

const BusinessFundsOrigins = ({
  fundsOrigin,
  handleBack,
  handleNext,
}: BusinessFundsOriginsProps) => {
  const theme = useTheme()

  const { loading, data } =
    useQuery<UltimateBeneficialOwnersData, UltimateBeneficialOwnersVars>(
      ULTIMATE_BENEFICIAL_OWNERS_QUERY,
    )

  const ultimateBeneficialOwners = data?.ultimateBeneficialOwners || []

  const everyUboHasFundsOrigin = ultimateBeneficialOwners.every((ubo) => !!ubo.fundsOrigin)
  const everyFundsOriginExists = fundsOrigin && everyUboHasFundsOrigin

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <BusinessFundsOriginDisplay fundsOrigin={fundsOrigin} />
      <Divider>Beneficiarios finales</Divider>
      {ultimateBeneficialOwners.map((ultimateBeneficialOwner, index) => (
        <UboFundsOriginDisplay
          key={index}
          ubo={ultimateBeneficialOwner}
        />
      ))}
      {!everyFundsOriginExists && (
        <IconCard
          elevation={0}
          icon={<Warning />}
          color={darken(theme.palette.warning.light, 0.6)}
          bgcolor={lighten(theme.palette.warning.light, 0.8)}
          sx={{ border: 1, borderColor: 'warning.dark' }}
        >
          Agrega todos los orígenes de fondos para completar este paso
        </IconCard>
      )}
      <Stack>
        <ButtonsContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              onClick={handleBack}
            >
              Atrás
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              variant='contained'
              disabled={!everyFundsOriginExists}
              onClick={handleNext}
            >
              Siguiente
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Stack>
    </React.Fragment>
  )
}

type FundsOriginStepProps = {
  isBusiness: boolean
  handleBack: () => void
  handleNext: () => void
}

export const FundsOriginStep = ({
  isBusiness,
  handleBack,
  handleNext,
}: FundsOriginStepProps) => {
  const theme = useTheme()

  const { loading, data } = useQuery<FundsOriginData, FundsOriginVars>(FUNDS_ORIGIN_QUERY)

  const fundsOrigin = data?.fundsOrigin

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <IconCard
        elevation={0}
        icon={<Info />}
        color={darken(theme.palette.info.light, 0.6)}
        bgcolor={lighten(theme.palette.info.light, 0.8)}
        sx={{ border: 1, borderColor: 'info.dark' }}
      >
        Se refiere al origen de los recursos que vas a utilizar para realizar transacciones en {SITE_NAME}.
      </IconCard>
      {isBusiness ? (
        <BusinessFundsOrigins
          fundsOrigin={fundsOrigin}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      ) : (
        <FundsOriginForm
          fundsOrigin={fundsOrigin}
          isBusiness={false}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      )}
    </React.Fragment>
  )
}
