import * as React from 'react'

import { Description } from '@mui/icons-material'
import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, alpha } from '@mui/material'

import { OnboardingDocumentDeleter } from './onboarding_document_deleter'
import { UserDocumentLink } from '../../components'

import type { OnboardingDocumentView } from '../../queries'

type OnboardingDocumentDisplayProps = {
  uboId?: string
  onboardingDocument: OnboardingDocumentView
}

const OnboardingDocumentDisplay = ({
  uboId,
  onboardingDocument,
}: OnboardingDocumentDisplayProps) => (
  <ListItem
    divider
    disablePadding
    sx={{ py: 1 }}
  >
    <ListItemAvatar>
      <Avatar
        sx={(theme) => ({
          color: 'text.primary',
          backgroundColor: alpha(
            theme.palette.primary[theme.palette.mode === 'light' ? 'dark' : 'light'],
            theme.palette.action.activatedOpacity,
          ),
        })}
      >
        <Description />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={onboardingDocument.description}
    />
    <ListItemText
      disableTypography
      sx={(theme) => ({ textAlign: 'right', minWidth: theme.spacing(10) })}
    >
      <OnboardingDocumentDeleter
        uboId={uboId}
        onboardingDocument={onboardingDocument}
      />
      <UserDocumentLink storageUrl={onboardingDocument.storageUrl} />
    </ListItemText>
  </ListItem>
)

type OnboardingDocumentsListProps = {
  uboId?: string
  onboardingDocuments: OnboardingDocumentView[]
}

export const OnboardingDocumentsList = ({
  uboId,
  onboardingDocuments,
}: OnboardingDocumentsListProps) => (
  <Grid item>
    <List
      dense
      aria-label='Documentos de diligencia reforzada'
      sx={{ py: 1 }}
    >
      {onboardingDocuments.length > 0 ? (
        <React.Fragment>
          <Divider />
          {onboardingDocuments.map((document, index) => (
            <OnboardingDocumentDisplay
              key={index}
              uboId={uboId}
              onboardingDocument={document}
            />
          ))}
        </React.Fragment>
      )  : (
        <ListItem>
          <ListItemText
            primary='No se han subido documentos'
            primaryTypographyProps={{ textAlign: 'center' }}
          />
        </ListItem>
      )}
    </List>
  </Grid>
)
