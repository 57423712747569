import * as React from 'react'

import { useQuery } from '@apollo/client'

import { FundsOriginProofsCreator } from './funds_origin_proofs_creator'
import { Dialog, Loading } from '../../../components'
import { BUSINESS_IDENTITY_QUERY } from '../../../queries'
import { IdentityDisplay } from '../identity_display'

import type {
  BusinessIdentityData,
  BusinessIdentityVars,
  OnboardingDocumentView,
} from '../../../queries'

type BusinessFundsOriginProofsDisplayProps = {
  fundsOriginProofs: OnboardingDocumentView[]
}

export const BusinessFundsOriginProofsDisplay = ({
  fundsOriginProofs,
}: BusinessFundsOriginProofsDisplayProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } =
    useQuery<BusinessIdentityData, BusinessIdentityVars>(BUSINESS_IDENTITY_QUERY)

  const companyName =  data?.businessIdentity.companyName

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <IdentityDisplay
        type='BUSINESS'
        name={companyName}
        isValid={fundsOriginProofs.length > 0}
        openDialog={openDialog}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`Prueba de origen de fondos para ${companyName}`}
      >
        <FundsOriginProofsCreator
          fundsOriginProofs={fundsOriginProofs}
          isBusiness={true}
          handleBack={closeDialog}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}
