import * as React from 'react'

import { Divider, MenuItem, Stack } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { Select } from 'formik-mui'
import { MobileDatePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import { DocumentUpload } from '../components'
import { POI_CONTENT_TYPES } from '../queries'
import { hasValidSize, minPoiDate } from '../services'

const hasValidContentType = (file: File | undefined) => (
  !file || POI_CONTENT_TYPES.includes(file.type)
)

export type DocumentType = 'ID_CARD' | 'PASSPORT'

export type IdentityProofsFormValues = {
  documentType: string
  expirationDate: Date | null
  frontImage: File | undefined
  backImage: File | undefined
}

export const getIdentityProofsInitialValues: IdentityProofsFormValues = {
  documentType: '',
  expirationDate: null,
  frontImage: undefined,
  backImage: undefined,
}

export const identityProofsValidationSchema = (
  Yup.object().shape({
    documentType: Yup.string()
      .required('Este campo es obligatorio'),
    expirationDate: Yup.date()
      .typeError('La fecha ingresada es inválida')
      .min(minPoiDate(), 'La fecha ingresada debe ser en el futuro')
      .required('Este campo es obligatorio'),
    frontImage: Yup.mixed()
      .required('Este campo es obligatorio')
      .test(
        'fileType',
        'Debes subir un archivo HEIC/JPG/PNG',
        (value) => hasValidContentType(value),
      )
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
    backImage: Yup.mixed()
      .when('documentType', {
        is: 'ID_CARD',
        then: Yup.mixed().required('Este campo es obligatorio'),
      })
      .test(
        'fileType',
        'Debes subir un archivo HEIC/JPG/PNG',
        (value) => hasValidContentType(value),
      )
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
  })
)

const DOCUMENT_TYPES = [
  {
    label: 'Cédula de identidad',
    value: 'ID_CARD',
  },
  {
    label: 'Pasaporte',
    value: 'PASSPORT',
  },
]

export const IdentityProofsFields = () => {
  const { values } = useFormikContext<IdentityProofsFormValues>()
  const isIdCard = values.documentType === 'ID_CARD'

  return (
    <Stack spacing={3}>
      <Field
        required
        name='documentType'
        type='text'
        label='Tipo de documento'
        component={Select}
      >
        {DOCUMENT_TYPES.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='expirationDate'
        type='date'
        label='Fecha de expiración'
        component={MobileDatePicker}
        minDate={minPoiDate()}
        views={['year', 'month', 'day']}
        inputFormat='yyyy/MM/dd'
        toolbarTitle='Ingresa la fecha de expiración del documento'
        closeOnSelect
      />
      {isIdCard && <Divider>Imagen frontal</Divider>}
      <DocumentUpload
        name='frontImage'
        imageAlt='Imagen Frontal'
        contentTypes={POI_CONTENT_TYPES}
        allowWebcam
      />
      {isIdCard && (
        <React.Fragment>
          <Divider>Imagen trasera</Divider>
          <DocumentUpload
            name='backImage'
            imageAlt='Imagen Trasera'
            contentTypes={POI_CONTENT_TYPES}
            allowWebcam
          />
        </React.Fragment>
      )}
    </Stack>
  )
}
