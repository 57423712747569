import * as React from 'react'

import { AddressProofForm } from './address_proof_form'
import { Dialog } from '../../../components'
import { addressToString, getLastAddressProof } from '../../../services'
import { IdentityDisplay } from '../identity_display'

import type { UltimateBeneficialOwner } from '../../../queries'

type UboAddressProofDisplayProps = {
  ubo: UltimateBeneficialOwner
}

export const UboAddressProofDisplay = ({
  ubo,
}: UboAddressProofDisplayProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const addressProof = getLastAddressProof(ubo.onboardingDocuments)
  const address = addressToString(ubo)

  return (
    <React.Fragment>
      <IdentityDisplay
        type='PERSON'
        name={`${ubo.givenName} ${ubo.familyName}`}
        isValid={!!addressProof}
        openDialog={openDialog}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`Prueba de dirección para ${ubo.givenName} ${ubo.familyName}`}
      >
        <AddressProofForm
          uboId={ubo.id}
          addressProof={addressProof}
          address={address}
          isBusiness={false}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}
