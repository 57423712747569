import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Warning } from '@mui/icons-material'
import { Button, Divider, Stack, darken, lighten, useTheme } from '@mui/material'

import { BusinessFundsOriginProofsDisplay } from './business_funds_origin_proofs_display'
import { FundsOriginDisclaimer } from './funds_origin_disclaimer'
import { FundsOriginProofsCreator } from './funds_origin_proofs_creator'
import { UboFundsOriginProofsDisplay } from './ubo_funds_origin_proofs_display'
import { ButtonContainer, ButtonsContainer, IconCard, Loading } from '../../../components'
import { ONBOARDING_DOCUMENTS_QUERY, ULTIMATE_BENEFICIAL_OWNERS_QUERY } from '../../../queries'
import { hasDocument } from '../../../services'

import type {
  OnboardingDocumentView,
  OnboardingDocumentsData,
  OnboardingDocumentsVars,
  UltimateBeneficialOwnersData,
  UltimateBeneficialOwnersVars,
} from '../../../queries'

type BusinessFundsOriginsProofsProps = {
  fundsOriginProofs: OnboardingDocumentView[]
  handleBack: () => void
  handleNext: () => void
}

const BusinessFundsOriginsProofs = ({
  fundsOriginProofs,
  handleBack,
  handleNext,
}: BusinessFundsOriginsProofsProps) => {
  const theme = useTheme()

  const { loading, data } =
    useQuery<UltimateBeneficialOwnersData, UltimateBeneficialOwnersVars>(
      ULTIMATE_BENEFICIAL_OWNERS_QUERY,
    )

  const ubos = data?.ultimateBeneficialOwners || []

  const everyUboHasFundsOriginProofs =
    ubos.every((ubo) => hasDocument(ubo.onboardingDocuments, 'FUNDS_ORIGIN_PROOF'))
  const everyFundsOriginProofsExists = fundsOriginProofs.length > 0 && everyUboHasFundsOriginProofs

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <BusinessFundsOriginProofsDisplay fundsOriginProofs={fundsOriginProofs} />
      <Divider>Beneficiarios finales</Divider>
      {ubos.map((ultimateBeneficialOwner, index) => (
        <UboFundsOriginProofsDisplay
          key={index}
          ubo={ultimateBeneficialOwner}
        />
      ))}
      {!everyFundsOriginProofsExists && (
        <IconCard
          elevation={0}
          icon={<Warning />}
          color={darken(theme.palette.warning.light, 0.6)}
          bgcolor={lighten(theme.palette.warning.light, 0.8)}
          sx={{ border: 1, borderColor: 'warning.dark' }}
        >
          Agrega todas las pruebas de orígenes de fondos para completar este paso
        </IconCard>
      )}
      <Stack>
        <ButtonsContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              onClick={handleBack}
            >
              Atrás
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              variant='contained'
              disabled={!everyFundsOriginProofsExists}
              onClick={handleNext}
            >
              Continuar
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Stack>
    </React.Fragment>
  )
}

type FundsOriginProofsStepProps = {
  isBusiness: boolean
  handleBack: () => void
  handleNext: () => void
}

export const FundsOriginProofsStep = ({
  isBusiness,
  handleBack,
  handleNext,
}: FundsOriginProofsStepProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } = useQuery<OnboardingDocumentsData, OnboardingDocumentsVars>(
    ONBOARDING_DOCUMENTS_QUERY, {
      variables: { documentType: 'FUNDS_ORIGIN_PROOF' },
    })

  const fundsOriginProofs = data?.onboardingDocuments || []

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      {isBusiness ? (
        <BusinessFundsOriginsProofs
          fundsOriginProofs={fundsOriginProofs}
          handleBack={handleBack}
          handleNext={openDialog}
        />
      ) : (
        <FundsOriginProofsCreator
          fundsOriginProofs={fundsOriginProofs}
          isBusiness={false}
          handleBack={handleBack}
          handleNext={openDialog}
        />
      )}
      <FundsOriginDisclaimer
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
        handleNext={handleNext}
      />
    </React.Fragment>
  )
}
