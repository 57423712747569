import * as React from 'react'

import { useQuery } from '@apollo/client'

import { AddressProofForm } from './address_proof_form'
import { Dialog, Loading } from '../../../components'
import { BUSINESS_IDENTITY_QUERY } from '../../../queries'
import { IdentityDisplay } from '../identity_display'

import type {
  BusinessIdentityData,
  BusinessIdentityVars,
  OnboardingDocumentView,
} from '../../../queries'

type BusinessAddressProofDisplayProps = {
  addressProof?: OnboardingDocumentView
  address: string
}

export const BusinessAddressProofDisplay = ({
  addressProof,
  address,
}: BusinessAddressProofDisplayProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } =
    useQuery<BusinessIdentityData, BusinessIdentityVars>(BUSINESS_IDENTITY_QUERY)

  const companyName =  data?.businessIdentity.companyName

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <IdentityDisplay
        type='BUSINESS'
        name={companyName}
        isValid={!!addressProof}
        openDialog={openDialog}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`Prueba de dirección para ${companyName}`}
      >
        <AddressProofForm
          addressProof={addressProof}
          address={address}
          isBusiness={true}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}
