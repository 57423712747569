import * as React from 'react'

import { Button, List, ListItem, ListItemText } from '@mui/material'

import { DocumentDisplay } from '../../document_display'

import type { OnboardingDocumentView } from '../../../queries'

type AddressProofDisplayProps = {
  handleShowForm: () => void
  addressProof: OnboardingDocumentView
  handleNext: () => void
}

export const AddressProofDisplay = ({
  handleShowForm,
  addressProof,
  handleNext,
}: AddressProofDisplayProps) => (
  <List>
    <ListItem disablePadding>
      <ListItemText
        primary={addressProof.description}
        primaryTypographyProps={{ textAlign: 'center', fontWeight: 500 }}
      />
    </ListItem>
    <ListItem>
      <DocumentDisplay
        contentType={addressProof.contentType}
        documentType={addressProof.documentType}
        storageUrl={addressProof.storageUrl}
      />
    </ListItem>
    <Button
      onClick={handleShowForm}
      variant='outlined'
      sx={{ mt: 3, mb: 2 }}
      fullWidth
    >
      Resubir otro documento
    </Button>
    <Button
      fullWidth
      onClick={handleNext}
      variant='contained'
      color='primary'
    >
      Siguiente
    </Button>
  </List>
)
