import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Delete, Info } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  darken,
  lighten,
  useTheme,
} from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { MARKET_ASSETS_QUERY } from 'shared/queries'

import { BaseCurrencyField } from './currency_field'
import { AssetBadge, IconCard, Loading, MarketAssetAdornment, OptionDisplay } from '../components'

import type { Fund, MarketAsset, MarketAssetsData, MarketAssetsVars } from '../queries'
import type { AutocompleteRenderInputParams } from '@mui/material'

export const validTotalPercentage = (percentages: number[]) => {
  const totalPercentage = percentages.reduce((acc, percentage) => acc + percentage, 0)

  if (totalPercentage === 0) {
    return true
  }

  return totalPercentage === 100
}

export type FundCompositionFormValues = {
  assets: string[]
  percentages: number[]
}

export const getFundCompositionInitialValues = (
  data?: Fund | undefined,
): FundCompositionFormValues => ({
  assets: data?.assets || ['', ''],
  percentages: data?.percentages || [0, 0],
})

export const fundCompositionValidationSchema: Yup.SchemaOf<FundCompositionFormValues> =
  Yup.object().shape({
    assets: Yup.array()
      .of(Yup.string()
        .required('Este campo es obligatorio'))
      .min(1, 'Selecciona al menos un activo'),
    percentages: Yup.array()
      .of(Yup.number()
        .required('El porcentaje es obligatorio')
        .positive('El porcentaje debe ser mayor que 0')
        .max(100, 'El porcentaje no puede ser mayor que 100')),
  })

type AssetFieldProps = {
  marketAssets: MarketAsset[]
  index: number
}

const AssetField = ({
  marketAssets,
  index,
}: AssetFieldProps) => {
  const { errors, setFieldValue, touched, values } = useFormikContext<FundCompositionFormValues>()

  const assetError = getIn(errors, `assets.${index}`)
  const assetTouched = getIn(touched, `assets.${index}`)

  const updateAssetSymbol = (newValue: MarketAsset) => {
    const newAssets = [...values.assets]
    newAssets[index] = newValue.symbol
    setFieldValue('assets', newAssets)
  }

  const availableAssets = marketAssets
    .filter(({ symbol }) => !values.assets.includes(symbol) || symbol === values.assets[index])

  return (
    <Autocomplete
      options={availableAssets}
      getOptionLabel={(option: MarketAsset) => option.name}
      value={marketAssets.find((marketAsset) => marketAsset.symbol === values.assets[index])}
      onChange={(_event, newValue) => newValue && updateAssetSymbol(newValue)}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: MarketAsset) => (
        <OptionDisplay {...props}>
          <AssetBadge
            symbol={option.symbol}
            height={20}
          />
          {option.name}
        </OptionDisplay>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={`assets.${index}`}
          error={assetTouched && !!assetError}
          helperText={assetError}
          label='Selecciona un activo'
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            startAdornment: <MarketAssetAdornment symbol={values.assets[index]} />,
          }}
        />
      )}
      sx={{ flexGrow: 1, minWidth: '45%' }}
    />
  )
}

const PercentageField = ({ index }: { index: number }) => {
  const { errors, setFieldTouched, setFieldValue, touched, values } =
    useFormikContext<FundCompositionFormValues>()

  const percentageError = getIn(errors, `percentages.${index}`)
  const percentageTouched = getIn(touched, `percentages.${index}`)

  const updateAssetPercentage = (newValue: number) => {
    const newPercentages = [...values.percentages]
    newPercentages[index] = newValue
    setFieldValue('percentages', newPercentages)
  }

  return (
    <BaseCurrencyField
      required
      positive
      label='Porcentaje del fondo'
      name={`percentages.${index}`}
      value={values.percentages[index]}
      error={percentageTouched && !!percentageError}
      helperText={percentageTouched &&  percentageError}
      onBlur={() => setFieldTouched(`percentages.${index}`, true)}
      onChange={(newValue?: number) => newValue && updateAssetPercentage(newValue)}
      digits={2}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position='start'
            style={{ marginLeft: 5 }}
          >
            <small>%</small>
          </InputAdornment>
        ),
        inputProps: { style: { textAlign: 'right' } },
      }}
      sx={{ flexGrow: 1 }}
    />
  )
}

export const FundCompositionFields = () => {
  const theme = useTheme()
  const { setFieldTouched, setFieldValue, values } = useFormikContext<FundCompositionFormValues>()

  const { loading, data } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = data?.marketAssets || []

  const removeAsset = (index: number) => {
    const newAssets = values.assets.filter((_, i) => i !== index)
    const newPercentages = values.percentages.filter((_, i) => i !== index)
    setFieldValue('assets', newAssets.length === 0 ? [''] : newAssets)
    setFieldValue('percentages', newPercentages.length === 0 ? [0] : newPercentages)
    setFieldTouched(`percentages.${index}`, false, false)
    setFieldTouched(`assets.${index}`, false, false)
  }

  const addAsset = () => {
    const newAssets = [...values.assets, '']
    const newPercentages = [...values.percentages, 0]
    setFieldValue('assets', newAssets)
    setFieldValue('percentages', newPercentages)
  }

  return loading ? (
    <Loading />
  ) : (
    <Stack spacing={3}>
      {values.assets.map((_, index) => (
        <Stack
          key={index}
          direction='row'
          spacing={2}
        >
          <AssetField
            marketAssets={marketAssets}
            index={index}
          />
          <PercentageField index={index} />
          <IconButton
            onClick={() => removeAsset(index)}
            edge='end'
            sx={{ '&:hover': { backgroundColor: 'transparent', color: 'error.main' } }}
          >
            <Delete />
          </IconButton>
        </Stack>
      ),
      )}
      {!validTotalPercentage(values.percentages) && (
        <IconCard
          elevation={0}
          icon={<Info />}
          color={darken(theme.palette.warning.dark, 0.6)}
          bgcolor={lighten(theme.palette.warning.light, 0.8)}
          sx={{ border: 1, borderColor: 'warning.dark' }}
        >
          El porcentaje total debe llegar a 100%
        </IconCard>
      )}
      <Button
        disabled={values.assets.length >= marketAssets.length}
        variant='contained'
        onClick={addAsset}
      >
        Agregar activo
      </Button>
    </Stack>
  )
}
