import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Edit } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import { Form, Formik } from 'formik'

import {
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
} from '../../../components'
import {
  UltimateBeneficialOwnerFields,
  getUltimateBeneficialOwnerInitialValues as getInitialValues,
  getUltimateBeneficialOwnerMutationVars as getMutationVariables,
  ultimateBeneficialOwnerValidationSchema as validationSchema,
} from '../../../forms'
import {
  ULTIMATE_BENEFICIAL_OWNERS_QUERY,
  UPDATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION,
} from '../../../queries'
import { setFormError, translateError } from '../../../services'

import type { UltimateBeneficialOwnerFormValues as FormValues } from '../../../forms'
import type {
  UltimateBeneficialOwner,
  UpdateUltimateBeneficialOwnerData,
  UpdateUltimateBeneficialOwnerVars,
} from '../../../queries'
import type { FormikHelpers, FormikProps } from 'formik'

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
}: FormikProps<FormValues>) => (
  <Form style={{ width: '100%' }}>
    <UltimateBeneficialOwnerFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Modificar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type UltimateBeneficialOwnerUpdaterProps = {
  ultimateBeneficialOwner: UltimateBeneficialOwner
}

export const UltimateBeneficialOwnerUpdater = ({
  ultimateBeneficialOwner,
}: UltimateBeneficialOwnerUpdaterProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [updateUltimateBeneficialOwner] =
    useMutation<UpdateUltimateBeneficialOwnerData, UpdateUltimateBeneficialOwnerVars>(
      UPDATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          ULTIMATE_BENEFICIAL_OWNERS_QUERY,
        ],
      })

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const response = await updateUltimateBeneficialOwner({
      variables: {
        id: ultimateBeneficialOwner.id,
        ...getMutationVariables(values),
      },
    })

    if (response.data?.updateUltimateBeneficialOwner === 'OK!') {
      closeDialog()
      resetForm()
      return
    }

    setFormError(formRef, translateError(response))
  }

  return (
    <React.Fragment>
      <IconButton
        color='inherit'
        onClick={openDialog}
      >
        <Edit />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Modificar UBO'
        maxWidth='xs'
      >
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(ultimateBeneficialOwner)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => <InnerForm {...props} />}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}
