import * as React from 'react'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

import { BUSINESS_WEALTH_ORIGIN_LABELS, PERSON_WEALTH_ORIGIN_LABELS } from '../queries'

import type { WealthOrigin } from '../queries'

export type WealthOriginFormValues = {
  descriptions: string[]
  origins: string[]
}

export const getWealthOriginInitialValues = (
  data:  WealthOrigin | undefined,
): WealthOriginFormValues => ({
  descriptions: data?.descriptions || [],
  origins: data?.origins || [],
})

export const wealthOriginValidationSchema: Yup.SchemaOf<WealthOriginFormValues> =
  Yup.object().shape({
    origins: Yup.array()
      .of(Yup.string().required('Este campo es obligatorio'))
      .min(1, 'Selecciona al menos un origen de patrimonio'),
    descriptions: Yup.array()
      .of(Yup.string().required('Este campo es obligatorio')),
  })

const DescriptionField = ({ type }: { type: string }) => {
  const [touched, setTouched] = React.useState(false)
  const { isSubmitting, setFieldValue, values } = useFormikContext<WealthOriginFormValues>()

  const index = values.origins.findIndex((origin) => origin === type)
  const value = values.descriptions[index]

  const updateDescription = (description: string) => {
    const newDescriptions = [...values.descriptions]
    newDescriptions[index] = description
    setFieldValue('descriptions', newDescriptions)
  }

  return (
    <TextField
      required
      disabled={isSubmitting}
      type='text'
      label='Detalles sobre el origen de patrimonio'
      onBlur={() => setTouched(true)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        updateDescription(event.target.value)
      }}
      value={value}
      error={touched && !value}
      helperText={touched && !value && 'Este campo es obligatorio'}
    />
  )
}

type OriginOptionProps = {
  name: string
  type: string
  label: string
}

const OriginOption = ({
  name,
  type,
  label,
}: OriginOptionProps) => {
  const { isSubmitting, setFieldValue, values } = useFormikContext<WealthOriginFormValues>()

  const addOrRemoveValue = () => {
    const newOrigins = [...values.origins]
    const newDescriptions = [...values.descriptions]

    if (values.origins.includes(type)) {
      const index = values.origins.findIndex((origin) => origin === type)
      setFieldValue('origins', newOrigins.splice(index, 1) && newOrigins)
      setFieldValue('descriptions', newDescriptions.splice(index, 1) && newDescriptions)
    } else {
      setFieldValue('origins', newOrigins.push(type) && newOrigins)
      setFieldValue('descriptions', newDescriptions.push('') && newDescriptions)
    }
  }

  return (
    <React.Fragment>
      <FormControlLabel
        disabled={isSubmitting}
        checked={values.origins.includes(type)}
        control={<Checkbox />}
        label={(
          <React.Fragment>
            <Typography>{name}</Typography>
            <Typography
              color='text.secondary'
              fontSize='small'
            >{label}</Typography>
          </React.Fragment>
        )}
        onChange={() => addOrRemoveValue()}
      />
      {values.origins.includes(type) && (
        <DescriptionField type={type} />
      )}
    </React.Fragment>
  )
}

export const WealthOriginFields = ({ isBusiness }: { isBusiness: boolean }) => {
  const { errors, touched } = useFormikContext<WealthOriginFormValues>()

  const originLabels = isBusiness ? BUSINESS_WEALTH_ORIGIN_LABELS : PERSON_WEALTH_ORIGIN_LABELS

  return (
    <FormControl>
      <FormGroup>
        <Stack spacing={2}>
          {originLabels.map((value, index) => (
            <OriginOption
              key={index}
              label={value.label}
              type={value.type}
              name={value.name}
            />
          ))}
          <FormHelperText error>
            {touched['origins'] && errors['origins']}
            {touched['descriptions'] && errors['descriptions']}
          </FormHelperText>
        </Stack>
      </FormGroup>
    </FormControl>
  )
}
