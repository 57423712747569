import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import {
  BackdropLoading,
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  ErrorDisplay,
} from '../../../components'
import {
  OnboardingDocumentFields,
  onboardingDocumentInitialValues as initialValues,
  onboardingDocumentValidationSchema as validationSchema,
} from '../../../forms'
import {
  BUSINESS_FUNDS_ORIGIN_PROOF_DESCRIPTIONS,
  CREATE_ONBOARDING_DOCUMENT_MUTATION,
  ONBOARDING_DOCUMENTS_QUERY,
  PERSON_FUNDS_ORIGIN_PROOF_DESCRIPTIONS,
  ULTIMATE_BENEFICIAL_OWNERS_QUERY,
  UPDATE_ONBOARDING_DOCUMENT_MUTATION,
} from '../../../queries'
import {
  setFormError,
  translateError,
  uploadToStorage,
} from '../../../services'

import type { OnboardingDocumentFormValues as FormValues } from '../../../forms'
import type {
  CreateOnboardingDocumentData,
  CreateOnboardingDocumentVars,
  UpdateOnboardingDocumentData,
  UpdateOnboardingDocumentVars,
} from '../../../queries'

type StepFormProps = FormikProps<FormValues> & {
  isBusiness: boolean
  showWaitingMessage: boolean
}

const StepForm = ({
  isValid,
  isSubmitting,
  status,
  submitForm,
  isBusiness,
  showWaitingMessage,
}: StepFormProps) => (
  <Form>
    {showWaitingMessage && (
      <BackdropLoading message='Por favor espera mientras cargamos tu documento...' />
    )}
    <OnboardingDocumentFields
      descriptions={isBusiness ?
        BUSINESS_FUNDS_ORIGIN_PROOF_DESCRIPTIONS
        : PERSON_FUNDS_ORIGIN_PROOF_DESCRIPTIONS}
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          variant='contained'
          disabled={!isValid || isSubmitting}
          onClick={submitForm}
        >
          Agregar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FundsOriginProofCreatorProps = {
  uboId?: string
  isBusiness: boolean
}

export const FundsOriginProofCreator = ({
  uboId,
  isBusiness,
}: FundsOriginProofCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [showWaitingMessage, setShowWaitingMessage] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [createOnboardingDocument] =
    useMutation<CreateOnboardingDocumentData, CreateOnboardingDocumentVars>(
      CREATE_ONBOARDING_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateOnboardingDocument] =
    useMutation<UpdateOnboardingDocumentData, UpdateOnboardingDocumentVars>(
      UPDATE_ONBOARDING_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          ...(uboId ? [ULTIMATE_BENEFICIAL_OWNERS_QUERY] : []),
          {
            query: ONBOARDING_DOCUMENTS_QUERY,
            variables: { uboId, documentType: 'FUNDS_ORIGIN_PROOF' },
          },
        ],
      })

  const uploadFile = async (values: FormValues) => {
    if (!values.file) {
      setFormError(formRef, 'No se encontró el archivo de origen.')
      return false
    }

    const createResponse = await createOnboardingDocument({
      variables: {
        uboId,
        contentType: values.file.type,
        description: values.description,
        documentType: 'FUNDS_ORIGIN_PROOF',
      },
    })

    const proof = createResponse.data?.createOnboardingDocument

    if (!proof) {
      setFormError(formRef, translateError(createResponse))
      return false
    }

    const storageResponse = await uploadToStorage(proof.storagePost, values.file)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir tu archivo.')
      return false
    }

    const updateResponse = await updateOnboardingDocument({
      variables: {
        id: proof?.id || '',
        uboId,
      },
    })

    if (updateResponse.data?.updateOnboardingDocument === 'OK!') {
      return true
    }

    setFormError(formRef, translateError(updateResponse))
    return false
  }

  const handleSubmit = async (values: FormValues) => {
    setShowWaitingMessage(true)

    const response = await uploadFile(values)

    if (response) {
      closeDialog()
      setShowWaitingMessage(false)
    } else {
      setShowWaitingMessage(false)
    }
  }

  return (
    <React.Fragment>
      <Button
        onClick={openDialog}
        variant='contained'
      >
        Agregar documento (max. 5)
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title='Agregar documento'
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <StepForm
              {...props}
              isBusiness={isBusiness}
              showWaitingMessage={showWaitingMessage}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}
