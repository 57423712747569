import { gql } from '@apollo/client'

type BusinessOrigin =  'COMPANY_PROFIT'
  | 'WORKING_CAPITAL'
  | 'SALES_INCOME'
  | 'DIVIDENDS'
  | 'ROYALTIES_OR_LICENSES'
  | 'EXTERNAL_FINANCING'
  | 'OTHER'

type PersonOrigin = 'SALARY'
  | 'PROPERTY_RENTAL'
  | 'INVESTMENTS'
  | 'PENSION_OR_RETIREMENT'
  | 'GOVERNMENT_SUBSIDIES'
  | 'INHERITANCES'
  | 'DONATIONS'
  | 'AWARDS_OR_LOTTERIES'
  | 'OTHER'

type Origin = BusinessOrigin | PersonOrigin

type OriginLabel = {
  type: Origin
  name: string
  label: string
}

export const BUSINESS_FUNDS_ORIGIN_LABELS: OriginLabel[] = [
  {
    type: 'COMPANY_PROFIT',
    name: 'Utilidades de la Empresa',
    label: 'Ganancias netas obtenidas después de restar todos los gastos operativos',
  },
  {
    type: 'WORKING_CAPITAL',
    name: 'Capital de Trabajo',
    label: 'Ingresos obtenidos de la liquidez operativa de la empresa',
  },
  {
    type: 'SALES_INCOME',
    name: 'Ingresos por Servicios o Productos',
    label: 'Pagos recibidos por servicios prestados o venta de productos',
  },
  {
    type: 'DIVIDENDS',
    name: 'Dividendos de Inversiones',
    label: 'Distribuciones recibidas de inversiones en otras compañías',
  },
  {
    type: 'ROYALTIES_OR_LICENSES',
    name: 'Ingresos por Royalties o Licencias',
    label: 'Pagos recibidos por el uso de propiedad intelectual',
  },
  {
    type: 'EXTERNAL_FINANCING',
    name: 'Financiamiento Externo',
    label: 'Fondos recibidos a través de préstamos, emisiones de bonos o inversionistas',
  },
  {
    type: 'OTHER',
    name: 'Otros',
    label: 'Especifique cualquier otra fuente de ingresos no mencionada',
  },
]

export const PERSON_FUNDS_ORIGIN_LABELS: OriginLabel[] = [
  {
    type: 'SALARY',
    name: 'Sueldo',
    label: 'Ingresos regulares provenientes de un empleo a tiempo completo o parcial',
  },
  {
    type: 'PROPERTY_RENTAL',
    name: 'Renta de Propiedades',
    label: 'Ingresos obtenidos del arriendo de propiedades inmobiliarias',
  },
  {
    type: 'INVESTMENTS',
    name: 'Inversiones',
    label: 'Ingresos derivados de inversiones financieras, como dividendos de ' +
                 'acciones, intereses de bonos, etc',
  },
  {
    type: 'PENSION_OR_RETIREMENT',
    name: 'Pensiones y Jubilación',
    label: 'Pagos recibidos como parte de un plan de pensiones o fondos de jubilación',
  },
  {
    type: 'GOVERNMENT_SUBSIDIES',
    name: 'Subsidios o Ayudas Gubernamentales',
    label: 'Ingresos provenientes de programas de asistencia gubernamental',
  },
  {
    type: 'INHERITANCES',
    name: 'Herencias',
    label: 'Dinero o propiedades recibidas como parte de una herencia',
  },
  {
    type: 'DONATIONS',
    name: 'Donaciones',
    label: 'Ingresos recibidos en forma de donaciones o regalos',
  },
  {
    type: 'AWARDS_OR_LOTTERIES',
    name: 'Premios o Loterías',
    label: 'Dinero ganado a través de premios, concursos o loterías',
  },
  {
    type: 'OTHER',
    name: 'Otros orígenes de fondos',
    label: 'Especifique cualquier otra fuente de ingresos no mencionada',
  },
]

export const FUNDS_ORIGIN_LABELS = BUSINESS_FUNDS_ORIGIN_LABELS.concat(PERSON_FUNDS_ORIGIN_LABELS)

export type FundsOrigin = {
  id: string
  descriptions: string[]
  origins: Origin[]
}

export const FUNDS_ORIGIN_FIELDS = gql`
  fragment FundsOriginFields on FundsOrigin {
    id
    descriptions
    origins
  }
`

export type FundsOriginVars = {
  uboId?: string
}

export type FundsOriginData = {
  fundsOrigin: FundsOrigin
}

export const FUNDS_ORIGIN_QUERY = gql`
  ${FUNDS_ORIGIN_FIELDS}
  query FundsOrigin (
    $uboId: ID
   ) {
    fundsOrigin(
      uboId: $uboId
    ) {
      ...FundsOriginFields
    }
  }
`

export type UpdateFundsOriginVars = {
  uboId?: string
  descriptions: string[]
  origins: string[]
}

export type UpdateFundsOriginData = {
  updateFundsOrigin: string
}

export const UPDATE_FUNDS_ORIGIN_MUTATION = gql`
  mutation UpdateFundsOrigin(
    $uboId: ID
    $descriptions: [String!]!
    $origins: [String!]!
  ) {
    updateFundsOrigin(
      input: {
        uboId: $uboId
        descriptions: $descriptions
        origins: $origins
      }
    )
  }
`
