import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Info } from '@mui/icons-material'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  darken,
  lighten,
  useTheme,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import {
  BackdropLoading,
  ButtonContainer,
  ButtonsContainer,
  DocumentUpload,
  ErrorDisplay,
  IconCard,
  Loading,
} from '../../../components'
import {
  BUSINESS_PROOF_QUERY,
  CREATE_BUSINESS_PROOF_MUTATION,
  UPDATE_BUSINESS_PROOF_MUTATION,
} from '../../../queries'
import {
  hasValidSize,
  setFormError,
  translateError,
  uploadToStorage,
} from '../../../services'
import { DocumentDisplay } from '../../document_display'

import type {
  BusinessProofData,
  BusinessProofVars,
  BusinessProofView,
  CreateBusinessProofData,
  CreateBusinessProofVars,
  UpdateBusinessProofData,
  UpdateBusinessProofVars,
} from '../../../queries'

const hasValidContentType = (file: File | undefined) => (
  !file || file.type === 'application/pdf'
)

export type FormValues = {
  file: File | undefined
}

export const getInitialValues: FormValues = {
  file: undefined,
}

export const validationSchema = (
  Yup.object().shape({
    file: Yup.mixed()
      .required('Este campo es obligatorio')
      .test(
        'fileType',
        'Debes subir un archivo PDF',
        (value) => hasValidContentType(value),
      )
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
  })
)

type StepFormProps = FormikProps<FormValues> & {
  handleBack: () => void
  showWaitingMessage: boolean
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  handleBack,
  showWaitingMessage,
}: StepFormProps) => {
  const theme = useTheme()

  return (
    <Form>
      {showWaitingMessage && (
        <BackdropLoading message='Por favor espera mientras cargamos tu documento...' />
      )}
      <IconCard
        elevation={0}
        icon={<Info />}
        color={darken(theme.palette.info.light, 0.6)}
        bgcolor={lighten(theme.palette.info.light, 0.8)}
        sx={{ border: 1, borderColor: 'info.dark', marginBottom: 3 }}
      >
        Agrega el documento de <strong>constitución de la empresa</strong> con fecha de emisión menor a 3 meses.
      </IconCard>
      <DocumentUpload
        name='file'
        imageAlt='Constitución empresa'
        contentTypes={['application/pdf']}
      />
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={handleBack}
            variant='outlined'
          >
            Atrás
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='primary'
          >
            {isSubmitting ? 'Cargando...' : 'Continuar'}
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type BusinessProofDisplayProps = {
  handleBack: () => void
  handleNext: () => void
  handleShowForm: () => void
  businessProof: BusinessProofView
}

const BusinessProofDisplay = ({
  handleBack,
  handleNext,
  handleShowForm,
  businessProof,
}: BusinessProofDisplayProps) => (
  <List>
    <ListItem disablePadding>
      <ListItemText
        primary='Constitución de la empresa'
        primaryTypographyProps={{ textAlign: 'center', fontWeight: 500 }}
      />
    </ListItem>
    <ListItem>
      <DocumentDisplay
        contentType={businessProof.contentType}
        documentType={businessProof.documentType}
        storageUrl={businessProof.storageUrl}
      />
    </ListItem>
    <Button
      onClick={handleShowForm}
      variant='outlined'
      sx={{ mt: 3, mb: 2 }}
      fullWidth
    >
      Resubir otro documento
    </Button>
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleBack}
          variant='outlined'
        >
          Atrás
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleNext}
          variant='contained'
          color='primary'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </List>
)

type BusinessProofStepProps = {
  handleBack: () => void
  handleNext: () => void
}

export const BusinessProofStep = ({
  handleBack,
  handleNext,
}: BusinessProofStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [showForm, setShowForm] = React.useState(false)
  const [showWaitingMessage, setShowWaitingMessage] = React.useState(false)

  const { loading, data } =
    useQuery<BusinessProofData, BusinessProofVars>(BUSINESS_PROOF_QUERY)

  const businessProof = data?.businessProof

  const [createBusinessProof] =
    useMutation<CreateBusinessProofData, CreateBusinessProofVars>(
      CREATE_BUSINESS_PROOF_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateBusinessProof] =
    useMutation<UpdateBusinessProofData, UpdateBusinessProofVars>(
      UPDATE_BUSINESS_PROOF_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          BUSINESS_PROOF_QUERY,
        ],
      })

  const uploadFile = async (file: File | undefined) => {
    if (!file) {
      setFormError(formRef, 'No se encontró el archivo de origen.')
      return false
    }

    const createResponse = await createBusinessProof({
      variables: {
        contentType: file.type,
        documentType: 'COMPANY_CONSTITUTION',
      },
    })

    const proof = createResponse.data?.createBusinessProof

    if (!proof) {
      setFormError(formRef, translateError(createResponse))
      return false
    }

    const storageResponse = await uploadToStorage(proof.storagePost, file)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir tu archivo.')
      return false
    }

    const updateResponse = await updateBusinessProof({
      variables: {
        id: proof?.id || '',
      },
    })

    if (updateResponse.data?.updateBusinessProof === 'OK!') {
      return true
    }

    setFormError(formRef, translateError(updateResponse))
    return false
  }

  const handleSubmit = async (values: FormValues) => {
    setShowWaitingMessage(true)

    const response = await uploadFile(values.file)

    if (response) {
      handleNext()
    } else {
      setShowWaitingMessage(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (!showForm && businessProof) ? (
    <BusinessProofDisplay
      handleBack={handleBack}
      handleNext={handleNext}
      handleShowForm={() => setShowForm(true)}
      businessProof={businessProof}
    />
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <StepForm
            handleBack={handleBack}
            showWaitingMessage={showWaitingMessage}
            {...props}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
