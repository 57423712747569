import { gql } from '@apollo/client'

import { STORAGE_POST_FIELDS } from './storage_post'

import type { StoragePost } from './storage_post'

type BusinessContentType = 'application/pdf'

type BusinessDocumentType = 'COMPANY_CONSTITUTION'

export type BusinessProof = {
  id: string
  contentType: string
  documentType: BusinessDocumentType
  storagePost: StoragePost
}

export const BUSINESS_PROOF_FIELDS = gql`
  ${STORAGE_POST_FIELDS}
  fragment BusinessProofFields on BusinessProof {
    id
    contentType
    documentType
    storagePost {
      ...StoragePostFields
    }
  }
`

export type CreateBusinessProofVars = {
  contentType: string
  documentType: string
}

export type CreateBusinessProofData = {
  createBusinessProof: BusinessProof
}

export const CREATE_BUSINESS_PROOF_MUTATION = gql`
  ${BUSINESS_PROOF_FIELDS}
  mutation CreateBusinessProof(
    $contentType: String!
    $documentType: String!
  ) {
    createBusinessProof(
      input: {
        contentType: $contentType
        documentType: $documentType
      }
    ) {
      ...BusinessProofFields
    }
  }
`

export type UpdateBusinessProofVars = {
  id: string
}

export type UpdateBusinessProofData = {
  updateBusinessProof: string
}

export const UPDATE_BUSINESS_PROOF_MUTATION = gql`
  mutation UpdateBusinessProof(
    $id: ID!
  ) {
    updateBusinessProof(
      input: {
        id: $id
      }
    )
  }
`

export type BusinessProofView = {
  id: string
  contentType: BusinessContentType
  documentType: BusinessDocumentType
  storageUrl?: string
}

export const BUSINESS_PROOF_VIEW_FIELDS = gql`
  fragment BusinessProofViewFields on BusinessProofView {
    id
    contentType
    documentType
    storageUrl
  }
`

export type BusinessProofVars = Record<string, never>

export type BusinessProofData = {
  businessProof: BusinessProofView
}

export const BUSINESS_PROOF_QUERY = gql`
  ${BUSINESS_PROOF_VIEW_FIELDS}
  query BusinessProof {
    businessProof {
      ...BusinessProofViewFields
    }
  }
`
