import * as React from 'react'

import type { PaletteMode, ThemeOptions } from '@mui/material'

export type DynamicColor = { [key in PaletteMode]: string }
export type DynamicColors = { [key: string]: DynamicColor }

export type ThemeColors = { [key in PaletteMode]: string }
export type Themes = { [key in PaletteMode]: ThemeOptions }

export type ThemeConfig = {
  dynamicColors: DynamicColors
  themeColors: ThemeColors
  themes: Themes
}

type ColorModeContextType = {
  setColorMode: (newValue: PaletteMode, permanent?: boolean) => void
  colorMode: PaletteMode | undefined
}

const createInitialContext = (): ColorModeContextType => ({
  setColorMode: () => null,
  colorMode: undefined,
})

export const ColorModeContext = React.createContext(createInitialContext())
