import * as React from 'react'

import { OpenInNew } from '@mui/icons-material'
import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'

import { Image } from '../components'

type ContentType = 'application/pdf'
 | 'image/heic'
 | 'image/jpeg'
 | 'image/jpg'
 | 'image/png'

const getContentTypeLabel = (contentType: ContentType) => {
  switch (contentType) {
  case 'application/pdf': return 'Documento PDF'
  case 'image/heic': return 'Imagen HEIC'
  case 'image/jpeg': return 'Imagen JPEG'
  case 'image/jpg': return 'Imagen JPG'
  case 'image/png': return 'Imagen PNG'
  }
}

type DocumentDisplayProps = {
  contentType: ContentType
  documentType: string
  storageUrl?: string
}

export const DocumentDisplay = ({
  contentType,
  documentType,
  storageUrl,
}: DocumentDisplayProps) => {
  if (!storageUrl) {
    return (
      <ListItemText
        primary='No se ha subido una imagen.'
        secondary='Es posible que se haya producido un error mientras se subía la imagen.'
      />
    )
  } else if (['image/heic', 'application/pdf'].includes(contentType)) {
    return (
      <ListItemButton
        disableGutters
        component='a'
        target='_blank'
        href={storageUrl}
        sx={{ pl: 2 }}
      >
        <ListItemText
          primary='Abrir documento en otra pestaña'
          secondary={`Tipo de archivo: ${getContentTypeLabel(contentType)}`}
        />
        <ListItemAvatar>
          <Avatar>
            <OpenInNew />
          </Avatar>
        </ListItemAvatar>
      </ListItemButton>
    )
  }

  return (
    <a
      style={{ width: '100%' }}
      href={storageUrl}
      target='_blank'
      rel='noreferrer'
    >
      <Image
        src={storageUrl}
        alt={documentType}
        fit='contain'
        duration={300}
        wrapperStyle={{ height: 'min(20vh, 250px)' }}
      />
    </a>
  )
}
