import { gql } from '@apollo/client'

import { STORAGE_POST_FIELDS } from './storage_post'

import type { StoragePost } from './storage_post'

type OnboardingDocumentContentType = 'application/pdf'
  | 'image/heic'
  | 'image/jpeg'
  | 'image/jpg'
  | 'image/png'

export const ONBOARDING_DOCUMENT_CONTENT_TYPES =
  ['application/pdf', 'image/heic', 'image/jpeg', 'image/jpg', 'image/png']

export type OnboardingDocumentType = 'ADDRESS_PROOF'
  | 'FUNDS_ORIGIN_PROOF'
  | 'WEALTH_ORIGIN_PROOF'
  | 'FUNDS_ORIGIN'
  | 'UBO'
  | 'OTHER'

export const ONBOARDING_DOCUMENT_TYPE_LABELS:
  { [key in OnboardingDocumentType]: string } = {
    'ADDRESS_PROOF': 'Prueba de dirección',
    'FUNDS_ORIGIN_PROOF': 'Prueba de origen de fondo',
    'WEALTH_ORIGIN_PROOF': 'Prueba de origen de patrimonio',
    'FUNDS_ORIGIN': 'Declaración origen de fondos (UAF)',
    'UBO': 'Declaración de UBOs (UAF)',
    'OTHER': 'Otro tipo de documento',
  }

export const PERSON_ADDRESS_PROOF_DESCRIPTIONS = [
  'Recibos de servicios básicos (luz, agua, gas)',
  'Extractos bancarios',
  'Contrato de arriendo legalizado ante notario',
  'Escritura de propiedad de la residencia actual',
]

export const BUSINESS_ADDRESS_PROOF_DESCRIPTIONS = [
  'Boletas de servicios de la empresa (luz, agua, etc.)',
  'Documentos del SII',
  'Contrato de arriendo de local',
  'Escritura de la propiedad comercial o industrial',
]

export const PERSON_FUNDS_ORIGIN_PROOF_DESCRIPTIONS = [
  'Liquidaciones de sueldo',
  'Certificado de AFP',
  'Escritura pública de compraventa',
  'Extracto bancario',
  'Declaraciones de impuestos',
  'Comprobante de dividendos',
  'Contrato de préstamo',
]

export const BUSINESS_FUNDS_ORIGIN_PROOF_DESCRIPTIONS = [
  'Estados financieros',
  'Certificados del SII',
  'Contratos de compraventa',
  'Comprobantes de financiamiento',
]

export const PERSON_WEALTH_ORIGIN_PROOF_DESCRIPTIONS = [
  'Certificado de posesión efectiva emitido por el Registro Civil',
  'Escritura de propiedades',
  'Certificado de inversiones',
  'Historial de cotizaciones previsionales',
  'Contrato de compraventa de vehículos o propiedades',
  'Contrato de trabajo',
]

export const BUSINESS_WEALTH_ORIGIN_PROOF_DESCRIPTIONS = [
  'Certificado de acciones',
  'Escritura de propiedades comerciales',
  'Estado financieros',
  'Contrato de compraventa de bienes empresariales',
  'Declaraciones de impuestos',
]

export type OnboardingDocument = {
  id: string
  documentType: string
  contentType: string
  description: string
  storagePost: StoragePost
}

export const ONBOARDING_DOCUMENT_FIELDS = gql`
  ${STORAGE_POST_FIELDS}
  fragment OnboardingDocumentFields on OnboardingDocument {
    id
    documentType
    contentType
    description
    storagePost {
      ...StoragePostFields
    }
  }
`

export type OnboardingDocumentView = {
  id: string
  createdAt: number
  documentType: OnboardingDocumentType
  contentType: OnboardingDocumentContentType
  description: string
  storageUrl?: string
}

export const ONBOARDING_DOCUMENT_VIEW_FIELDS = gql`
  fragment OnboardingDocumentViewFields on OnboardingDocumentView {
    id
    createdAt
    documentType
    contentType
    description
    storageUrl
  }
`

export type OnboardingDocumentsVars = {
  uboId?: string
  documentType: OnboardingDocumentType
}

export type OnboardingDocumentsData = {
  onboardingDocuments: OnboardingDocumentView[]
}

export const ONBOARDING_DOCUMENTS_QUERY = gql`
  ${ONBOARDING_DOCUMENT_VIEW_FIELDS}
  query OnboardingDocuments (
    $uboId: ID
    $documentType: String!
   ) {
    onboardingDocuments(
      uboId: $uboId
      documentType: $documentType
    ) {
      ...OnboardingDocumentViewFields
    }
  }
`

export type CreateOnboardingDocumentVars = {
  uboId?: string
  contentType: string
  description: string
  documentType: string
}

export type CreateOnboardingDocumentData = {
  createOnboardingDocument: OnboardingDocument
}

export const CREATE_ONBOARDING_DOCUMENT_MUTATION = gql`
  ${ONBOARDING_DOCUMENT_FIELDS}
  mutation CreateOnboardingDocument(
    $uboId: ID
    $contentType: String!
    $description: String!
    $documentType: String!
  ) {
    createOnboardingDocument(
      input: {
        uboId: $uboId
        contentType: $contentType
        description: $description
        documentType: $documentType
      }
    ) {
      ...OnboardingDocumentFields
    }
  }
`

export type UpdateOnboardingDocumentVars = {
  id: string
  uboId?: string
}

export type UpdateOnboardingDocumentData = {
  updateOnboardingDocument: string
}

export const UPDATE_ONBOARDING_DOCUMENT_MUTATION = gql`
  mutation UpdateOnboardingDocument(
    $id: ID!
    $uboId: ID
  ) {
    updateOnboardingDocument(
      input: {
        id: $id
        uboId: $uboId
      }
    )
  }
`

export type DeleteOnboardingDocumentVars = {
  uboId?: string
  onboardingDocumentId: string
}

export type DeleteOnboardingDocumentData = {
  deleteOnboardingDocument: string
}

export const DELETE_ONBOARDING_DOCUMENT_MUTATION = gql`
  mutation DeleteOnboardingDocument(
    $uboId: ID
    $onboardingDocumentId: ID!
  ) {
    deleteOnboardingDocument(
      input: {
        uboId: $uboId
        onboardingDocumentId: $onboardingDocumentId
      }
    )
  }
`
