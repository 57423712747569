import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Info, Warning } from '@mui/icons-material'
import { Button, Divider, Stack, darken, lighten, useTheme } from '@mui/material'

import { BusinessWealthOriginDisplay } from './business_wealth_origin_display'
import { UboWealthOriginDisplay } from './ubo_wealth_origin_display'
import { WealthOriginForm } from './wealth_origin_form'
import { ButtonContainer, ButtonsContainer, IconCard, Loading } from '../../../components'
import { ULTIMATE_BENEFICIAL_OWNERS_QUERY, WEALTH_ORIGIN_QUERY } from '../../../queries'

import type {
  UltimateBeneficialOwnersData,
  UltimateBeneficialOwnersVars,
  WealthOrigin,
  WealthOriginData,
  WealthOriginVars,
} from '../../../queries'

type BusinessWealthOriginsProps = {
  wealthOrigin?: WealthOrigin
  handleBack: () => void
  handleNext: () => void
}

const BusinessWealthOrigins = ({
  wealthOrigin,
  handleBack,
  handleNext,
}: BusinessWealthOriginsProps) => {
  const theme = useTheme()

  const { loading, data } =
    useQuery<UltimateBeneficialOwnersData, UltimateBeneficialOwnersVars>(
      ULTIMATE_BENEFICIAL_OWNERS_QUERY,
    )

  const ultimateBeneficialOwners = data?.ultimateBeneficialOwners || []

  const everyUboHasWealthOrigin = ultimateBeneficialOwners.every((ubo) => !!ubo.wealthOrigin)
  const everyWealthOriginExists = wealthOrigin && everyUboHasWealthOrigin

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <BusinessWealthOriginDisplay wealthOrigin={wealthOrigin} />
      <Divider>Beneficiarios finales</Divider>
      {ultimateBeneficialOwners.map((ultimateBeneficialOwner, index) => (
        <UboWealthOriginDisplay
          key={index}
          ubo={ultimateBeneficialOwner}
        />
      ))}
      {!everyWealthOriginExists && (
        <IconCard
          elevation={0}
          icon={<Warning />}
          color={darken(theme.palette.warning.light, 0.6)}
          bgcolor={lighten(theme.palette.warning.light, 0.8)}
          sx={{ border: 1, borderColor: 'warning.dark' }}
        >
          Agrega todos los orígenes de patrimonio para completar este paso
        </IconCard>
      )}
      <Stack>
        <ButtonsContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              onClick={handleBack}
            >
              Atrás
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              variant='contained'
              disabled={!everyWealthOriginExists}
              onClick={handleNext}
            >
              Siguiente
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Stack>
    </React.Fragment>
  )
}

type WealthOriginStepProps = {
  isBusiness: boolean
  handleBack: () => void
  handleNext: () => void
}

export const WealthOriginStep = ({
  isBusiness,
  handleBack,
  handleNext,
}: WealthOriginStepProps) => {
  const theme = useTheme()
  const { loading, data } = useQuery<WealthOriginData, WealthOriginVars>(WEALTH_ORIGIN_QUERY)

  const wealthOrigin = data?.wealthOrigin

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <IconCard
        elevation={0}
        icon={<Info />}
        color={darken(theme.palette.info.light, 0.6)}
        bgcolor={lighten(theme.palette.info.light, 0.8)}
        sx={{ border: 1, borderColor: 'info.dark' }}
      >
        Se refiere al origen total del patrimonio y la riqueza acumulada a lo largo del tiempo.
      </IconCard>
      {isBusiness ? (
        <BusinessWealthOrigins
          wealthOrigin={wealthOrigin}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      ) : (
        <WealthOriginForm
          wealthOrigin={wealthOrigin}
          isBusiness={false}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      )}
    </React.Fragment>
  )
}
