import * as React from 'react'

import { FundsOriginForm } from './funds_origin_form'
import { Dialog } from '../../../components'
import { IdentityDisplay } from '../identity_display'

import type { UltimateBeneficialOwner } from '../../../queries'

type UboFundsOriginDisplayProps = {
  ubo: UltimateBeneficialOwner
}

export const UboFundsOriginDisplay = ({
  ubo,
}: UboFundsOriginDisplayProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <IdentityDisplay
        type='PERSON'
        name={`${ubo.givenName} ${ubo.familyName}`}
        isValid={!!ubo.fundsOrigin}
        openDialog={openDialog}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`Origen de fondos para ${ubo.givenName} ${ubo.familyName}`}
      >
        <FundsOriginForm
          uboId={ubo.id}
          fundsOrigin={ubo.fundsOrigin}
          isBusiness={false}
          handleBack={closeDialog}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}
