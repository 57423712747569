import * as React from 'react'

import {
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

type StepLayoutProps = {
  label: string
  children: React.ReactNode
}

const StepLayout = ({ label, children }: StepLayoutProps) => (
  <Container
    disableGutters
    maxWidth='xs'
  >
    <Paper
      elevation={12}
      variant='elevation'
      sx={{ px: 3, py: 3, my: 1 }}
    >
      <Stack
        sx={{ '& .MuiFormControl-root': { width: '100%' } }}
        spacing={3}
      >
        <Typography
          variant='h5'
          component='p'
          textAlign='center'
        >
          {label}
        </Typography>
        <Divider />
        {children}
      </Stack>
    </Paper>
  </Container>
)

type ResponsiveStepperProps = {
  activeStep: number
  stepLabels: string[]
  children: (step: number) => React.ReactNode
}

export const ResponsiveStepper = ({
  activeStep,
  stepLabels,
  children,
}: ResponsiveStepperProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <React.Fragment>
      <Stepper
        activeStep={activeStep}
        orientation={smallScreen ? 'vertical' : 'horizontal'}
      >
        {stepLabels.length > 1 && stepLabels.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
            {smallScreen && (
              <StepContent>
                <StepLayout label={label}>
                  {children(index)}
                </StepLayout>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
      {(!smallScreen && activeStep < stepLabels.length) && (
        <Box
          flexGrow={1}
          display='flex'
          alignItems='center'
          my={2}
        >
          <StepLayout label={stepLabels[activeStep]}>
            {children(activeStep)}
          </StepLayout>
        </Box>
      )}
    </React.Fragment>
  )
}
