import { gql } from '@apollo/client'

export type RenewalType = 'USER_IDENTITY_PROOFS' | 'UBO_IDENTITY_PROOFS'

export type Renewal = {
  deadline: string
  resourceData?: string
  resourceId: string
  type: RenewalType
}

export const RENEWAL_FIELDS = gql`
  fragment RenewalFields on Renewal {
    deadline
    resourceData
    resourceId
    type
  }
`

export const getRenewalHref = (renewal: Renewal) => {
  switch (renewal.type) {
  case 'USER_IDENTITY_PROOFS': return '/renewal/identity-proofs/'
  case 'UBO_IDENTITY_PROOFS': return '/renewal/identity-proofs/?'
                                     + `uboId=${encodeURIComponent(renewal.resourceId)}`
  }
}

export const getRenewalBannerMessage = (renewal: Renewal) => {
  switch (renewal.type) {
  case 'USER_IDENTITY_PROOFS': return 'Actualice sus documentos de identidad antes del '
                                      + renewal.deadline
  case 'UBO_IDENTITY_PROOFS': return 'Actualice los documentos de identidad de '
                                     + `${renewal.resourceData} antes del ${renewal.deadline}`
  }
}

export const getRenewalNotificationLabel = (renewalType: RenewalType) => {
  switch (renewalType) {
  case 'USER_IDENTITY_PROOFS': return 'Documentos de identidad:'
  case 'UBO_IDENTITY_PROOFS': return 'Documentos de identidad de beneficiario final:'
  }
}

export const getUboName = (uboId: string, type: RenewalType, renewals: Renewal[]) => {
  const renewal =
    renewals.find((renewal) => renewal.type === type && renewal.resourceId === uboId)

  return renewal?.resourceData
}
