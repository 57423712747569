const MAX_FILE_SIZE = 16 * 1024 * 1024

export const hasValidSize = (file: File | undefined) => (
  !file || file.size <= MAX_FILE_SIZE
)

export const minPoiDate = () => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)
  return tomorrow
}
