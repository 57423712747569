import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Info } from '@mui/icons-material'
import {
  Button,
  Stack,
  darken,
  lighten,
  useTheme,
} from '@mui/material'

import { FundsOriginProofCreator } from './funds_origin_proof_creator'
import {
  ButtonContainer,
  ButtonsContainer,
  IconCard,
  Loading,
} from '../../../components'
import { FUNDS_ORIGIN_LABELS, FUNDS_ORIGIN_QUERY } from '../../../queries'
import { OnboardingDocumentsList } from '../onboarding_documents_list'

import type { FundsOriginData, FundsOriginVars, OnboardingDocumentView } from '../../../queries'

type FundsOriginProofsDisclaimerProps = {
  uboId?: string
}

const FundsOriginProofsDisclaimer = ({
  uboId,
}: FundsOriginProofsDisclaimerProps) => {
  const theme = useTheme()

  const { loading, data } = useQuery<FundsOriginData, FundsOriginVars>(FUNDS_ORIGIN_QUERY, {
    variables: { uboId },
  })

  const origins = data?.fundsOrigin?.origins || []

  return loading ? (
    <Loading />
  ) : (
    <IconCard
      elevation={0}
      icon={<Info />}
      color={darken(theme.palette.info.light, 0.6)}
      bgcolor={lighten(theme.palette.info.light, 0.8)}
      sx={{ border: 1, borderColor: 'info.dark' }}
    >
      Agrega documentos con fecha de emisión menor a 3 meses para justificar
      {origins.length > 1 ?
        ' los siguientes orígenes de fondos:'
        : ' el siguiente origen de fondos:'}
      <br style={{ display: 'block', content: '""', height: '0.5em' }} />
      {origins.map((origin, index) => (
        <span
          key={index}
          style={{
            display: 'list-item',
            listStyleType: 'disc',
            listStylePosition: 'inside',
            fontWeight: 600,
          }}
        >
          {FUNDS_ORIGIN_LABELS.find((fundOrigin) => fundOrigin.type === origin)?.name}
        </span>
      ))}
    </IconCard>
  )
}

type FundsOriginProofsCreatorProps = {
  uboId?: string
  isBusiness: boolean
  fundsOriginProofs: OnboardingDocumentView[]
  handleBack: () => void
  handleNext: () => void
}

export const FundsOriginProofsCreator = ({
  uboId,
  isBusiness,
  fundsOriginProofs,
  handleBack,
  handleNext,
}: FundsOriginProofsCreatorProps) => (
  <React.Fragment>
    <FundsOriginProofsDisclaimer uboId={uboId} />
    <OnboardingDocumentsList
      uboId={uboId}
      onboardingDocuments={fundsOriginProofs}
    />
    <FundsOriginProofCreator
      uboId={uboId}
      isBusiness={isBusiness}
    />
    <Stack mt={2}>
      <ButtonsContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            onClick={handleBack}
            variant='outlined'
            color='primary'
          >
            Atrás
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={fundsOriginProofs.length < 1}
            onClick={handleNext}
            variant='contained'
            color='primary'
          >
            Continuar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Stack>
  </React.Fragment>
)
