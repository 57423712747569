import * as React from 'react'

import { useQuery } from '@apollo/client'

import { WealthOriginForm } from './wealth_origin_form'
import { Dialog, Loading } from '../../../components'
import { BUSINESS_IDENTITY_QUERY } from '../../../queries'
import { IdentityDisplay } from '../identity_display'

import type {
  BusinessIdentityData,
  BusinessIdentityVars,
  WealthOrigin,
} from '../../../queries'

type BusinessWealthOriginDisplayProps = {
  wealthOrigin?: WealthOrigin
}

export const BusinessWealthOriginDisplay = ({
  wealthOrigin,
}: BusinessWealthOriginDisplayProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const { loading, data } =
    useQuery<BusinessIdentityData, BusinessIdentityVars>(BUSINESS_IDENTITY_QUERY)

  const companyName =  data?.businessIdentity.companyName

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <IdentityDisplay
        type='BUSINESS'
        name={companyName}
        isValid={!!wealthOrigin}
        openDialog={openDialog}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`Origen de patrimonio para ${companyName}`}
      >
        <WealthOriginForm
          wealthOrigin={wealthOrigin}
          isBusiness={true}
          handleBack={closeDialog}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}
