import * as React from 'react'

import { CheckCircle, Domain, Edit, Person, Warning } from '@mui/icons-material'
import { Button, IconButton, Stack } from '@mui/material'

type IdentityDisplayProps = {
  type: 'BUSINESS' | 'PERSON'
  name?: string
  isValid: boolean
  openDialog: () => void
}

export const IdentityDisplay = ({
  type,
  name,
  isValid,
  openDialog,
}: IdentityDisplayProps) => (
  <Stack
    direction='row'
    alignItems='center'
    justifyContent='space-between'
    width='100%'
  >
    {isValid ? (
      <CheckCircle sx={{ color: 'success.main' }} />
    ) : (
      <Warning sx={{ color: 'warning.main' }} />
    )}
    <Button
      startIcon={type === 'BUSINESS' ? <Domain /> : <Person />}
      variant='text'
      onClick={openDialog}
      size='large'
    >
      {name}
    </Button>
    <IconButton onClick={openDialog}>
      <Edit />
    </IconButton>
  </Stack>
)
