import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'

import { AddressProofStep } from './address_proof_step'
import { FundsOriginProofsStep } from './funds_origin_proofs_step'
import { FundsOriginStep } from './funds_origin_step'
import { PendingRevisionStep } from './pending_revision_step'
import { WealthOriginProofsStep } from './wealth_origin_proofs_step'
import { WealthOriginStep } from './wealth_origin_step'
import { Loading, ResponsiveStepper } from '../../components'
import { ACCOUNT_INFORMATION_QUERY, UPDATE_VERIFICATION_MUTATION } from '../../queries'

import type {
  AccountInformationData,
  AccountInformationVars,
  UpdateVerificationData,
  UpdateVerificationVars,
} from '../../queries'

const STEP_LABELS = [
  'Prueba de dirección',
  'Origen de patrimonio',
  'Prueba de origen de patrimonio',
  'Origen de fondos',
  'Prueba de origen de fondos',
]

export const EnhancedOnboardingForm = () => {
  const [activeStep, setActiveStep] = React.useState(0)

  const { loading, data } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  const [updateVerification] =
    useMutation<UpdateVerificationData, UpdateVerificationVars>(UPDATE_VERIFICATION_MUTATION, {
      errorPolicy: 'all',
    })

  const isBusiness = data?.accountInformation.isBusiness

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const isOnConfirmationStep = (activeStep === STEP_LABELS.length)

  React.useEffect(() => {
    if (isOnConfirmationStep) {
      updateVerification()
    }
  }, [isOnConfirmationStep, updateVerification])

  if (loading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <ResponsiveStepper
        activeStep={activeStep}
        stepLabels={STEP_LABELS}
      >
        {(activeStep) => {
          switch (activeStep) {
          case 0: return (
            <AddressProofStep
              isBusiness={!!isBusiness}
              handleNext={handleNext}
            />
          )
          case 1: return (
            <WealthOriginStep
              isBusiness={!!isBusiness}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 2: return (
            <WealthOriginProofsStep
              isBusiness={!!isBusiness}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 3: return (
            <FundsOriginStep
              isBusiness={!!isBusiness}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 4: return (
            <FundsOriginProofsStep
              isBusiness={!!isBusiness}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          }
        }}
      </ResponsiveStepper>
      {(isOnConfirmationStep) && (
        <PendingRevisionStep />
      )}
    </React.Fragment>
  )
}
