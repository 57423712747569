import { gql } from '@apollo/client'

export type Fund = {
  id: string
  balance: number
  assets: string[]
  name: string
  percentages: number[]
  sharePrice: number
  symbol: string
}

export const FUND_FIELDS = gql`
  fragment FundFields on Fund {
    id
    balance
    assets
    name
    percentages
    sharePrice
    symbol
  }
`

export type Funds = {
  funds: Fund[]
  totalBalance: number
}

export const FUNDS_FIELDS = gql`
  ${FUND_FIELDS}
  fragment FundsFields on Funds {
    funds {
      ...FundFields
    }
    totalBalance
  }
`

export type FundsVars = Record<string, never>

export type FundsData = {
  funds: Funds
}

export const FUNDS_QUERY = gql`
  ${FUNDS_FIELDS}
  query Funds {
    funds {
      ...FundsFields
    }
  }
`

export type FundVars = {
  fundId: string
}

export type FundData = {
  fund: Fund
}

export const FUND_QUERY = gql`
  ${FUND_FIELDS}
  query Fund(
    $fundId: ID!
  ) {
    fund (
      fundId: $fundId
    ) {
      ...FundFields
    }
  }
`
