import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import {
  DELETE_ONBOARDING_DOCUMENT_MUTATION,
  ONBOARDING_DOCUMENTS_QUERY,
  ULTIMATE_BENEFICIAL_OWNERS_QUERY,
} from '../../queries'
import { translateError } from '../../services'
import { ErrorDisplay } from '../error_display'

import type {
  DeleteOnboardingDocumentData,
  DeleteOnboardingDocumentVars,
  OnboardingDocumentView,
} from '../../queries'

type OnboardingDocumentDeleterProps = {
  uboId?: string
  onboardingDocument: OnboardingDocumentView
}

export const OnboardingDocumentDeleter = ({
  uboId,
  onboardingDocument,
}: OnboardingDocumentDeleterProps) => {
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [deleteOnboardingDocument] =
    useMutation<DeleteOnboardingDocumentData, DeleteOnboardingDocumentVars>(
      DELETE_ONBOARDING_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          ...(uboId ? [ULTIMATE_BENEFICIAL_OWNERS_QUERY] : []),
          {
            query: ONBOARDING_DOCUMENTS_QUERY,
            variables: { uboId, documentType: onboardingDocument.documentType },
          },
        ],
      })

  const handleDelete = async () => {
    const response = await deleteOnboardingDocument({
      variables: {
        uboId,
        onboardingDocumentId: onboardingDocument.id,
      },
    })

    if (response.data?.deleteOnboardingDocument === 'OK!') {
      return
    }

    setErrorMsg(translateError(response))
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label='Eliminar documento'
        onClick={handleDelete}
        sx={{ '&:hover': { backgroundColor: 'transparent', color: 'error.main' } }}
      >
        <Delete />
      </IconButton>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
    </React.Fragment>
  )
}
