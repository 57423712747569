import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Warning } from '@mui/icons-material'
import { Button, Divider, Stack, darken, lighten, useTheme } from '@mui/material'

import { BusinessWealthOriginProofsDisplay } from './business_wealth_origin_proofs_display'
import { UboWealthOriginProofsDisplay } from './ubo_wealth_origin_proofs_display'
import { WealthOriginProofsCreator } from './wealth_origin_proofs_creator'
import { ButtonContainer, ButtonsContainer, IconCard, Loading } from '../../../components'
import { ONBOARDING_DOCUMENTS_QUERY, ULTIMATE_BENEFICIAL_OWNERS_QUERY } from '../../../queries'
import { hasDocument } from '../../../services'

import type {
  OnboardingDocumentView,
  OnboardingDocumentsData,
  OnboardingDocumentsVars,
  UltimateBeneficialOwnersData,
  UltimateBeneficialOwnersVars,
} from '../../../queries'

type BusinessWealthOriginsProofsProps = {
  wealthOriginProofs: OnboardingDocumentView[]
  handleBack: () => void
  handleNext: () => void
}

const BusinessWealthOriginsProofs = ({
  wealthOriginProofs,
  handleBack,
  handleNext,
}: BusinessWealthOriginsProofsProps) => {
  const theme = useTheme()

  const { loading, data } =
    useQuery<UltimateBeneficialOwnersData, UltimateBeneficialOwnersVars>(
      ULTIMATE_BENEFICIAL_OWNERS_QUERY,
    )

  const ubos = data?.ultimateBeneficialOwners || []

  const everyUboHasWealthOriginProofs =
    ubos.every((ubo) => hasDocument(ubo.onboardingDocuments, 'WEALTH_ORIGIN_PROOF'))
  const everyWealthOriginProofsExists =
    wealthOriginProofs.length > 0 && everyUboHasWealthOriginProofs

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <BusinessWealthOriginProofsDisplay wealthOriginProofs={wealthOriginProofs} />
      <Divider>Beneficiarios finales</Divider>
      {ubos.map((ultimateBeneficialOwner, index) => (
        <UboWealthOriginProofsDisplay
          key={index}
          ubo={ultimateBeneficialOwner}
        />
      ))}
      {!everyWealthOriginProofsExists && (
        <IconCard
          elevation={0}
          icon={<Warning />}
          color={darken(theme.palette.warning.light, 0.6)}
          bgcolor={lighten(theme.palette.warning.light, 0.8)}
          sx={{ border: 1, borderColor: 'warning.dark' }}
        >
          Agrega todas las pruebas de orígenes de patrimonio para completar este paso
        </IconCard>
      )}
      <Stack>
        <ButtonsContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              onClick={handleBack}
            >
              Atrás
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              variant='contained'
              disabled={!everyWealthOriginProofsExists}
              onClick={handleNext}
            >
              Siguiente
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Stack>
    </React.Fragment>
  )
}

type WealthOriginProofsStepProps = {
  isBusiness: boolean
  handleBack: () => void
  handleNext: () => void
}

export const WealthOriginProofsStep = ({
  isBusiness,
  handleBack,
  handleNext,
}: WealthOriginProofsStepProps) => {
  const { loading, data } = useQuery<OnboardingDocumentsData, OnboardingDocumentsVars>(
    ONBOARDING_DOCUMENTS_QUERY, {
      variables: { documentType: 'WEALTH_ORIGIN_PROOF' },
    })

  const wealthOriginProofs = data?.onboardingDocuments || []

  return loading ? (
    <Loading />
  ) : (
    isBusiness ? (
      <BusinessWealthOriginsProofs
        wealthOriginProofs={wealthOriginProofs}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    ) : (
      <WealthOriginProofsCreator
        wealthOriginProofs={wealthOriginProofs}
        isBusiness={false}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    )
  )
}
