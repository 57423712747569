import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Info } from '@mui/icons-material'
import { Button, darken, lighten, useTheme } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'

import { AddressProofDisplay } from './address_proof_display'
import {
  BackdropLoading,
  ButtonContainer,
  ButtonsContainer,
  ErrorDisplay,
  IconCard,
} from '../../../components'
import {
  OnboardingDocumentFields,
  onboardingDocumentInitialValues as initialValues,
  onboardingDocumentValidationSchema as validationSchema,
} from '../../../forms'
import {
  BUSINESS_ADDRESS_PROOF_DESCRIPTIONS,
  CREATE_ONBOARDING_DOCUMENT_MUTATION,
  ONBOARDING_DOCUMENTS_QUERY,
  PERSON_ADDRESS_PROOF_DESCRIPTIONS,
  ULTIMATE_BENEFICIAL_OWNERS_QUERY,
  UPDATE_ONBOARDING_DOCUMENT_MUTATION,
} from '../../../queries'
import {
  setFormError,
  translateError,
  uploadToStorage,
} from '../../../services'

import type { OnboardingDocumentFormValues as FormValues } from '../../../forms'
import type {
  CreateOnboardingDocumentData,
  CreateOnboardingDocumentVars,
  OnboardingDocumentView,
  UpdateOnboardingDocumentData,
  UpdateOnboardingDocumentVars,
} from '../../../queries'

type StepFormProps = FormikProps<FormValues> & {
  isBusiness: boolean
  showWaitingMessage: boolean
}

const StepForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isBusiness,
  showWaitingMessage,
}: StepFormProps) => (
  <Form>
    {showWaitingMessage && (
      <BackdropLoading message='Por favor espera mientras cargamos tu documento...' />
    )}
    <OnboardingDocumentFields
      descriptions={isBusiness ?
        BUSINESS_ADDRESS_PROOF_DESCRIPTIONS
        : PERSON_ADDRESS_PROOF_DESCRIPTIONS}
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='primary'
        >
          Siguiente
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type AddressProofFormProps = {
  uboId?: string
  addressProof?: OnboardingDocumentView
  isBusiness: boolean
  address: string
  handleNext: () => void
}

export const AddressProofForm = ({
  uboId,
  addressProof,
  isBusiness,
  address,
  handleNext,
}: AddressProofFormProps) => {
  const theme = useTheme()
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [showForm, setShowForm] = React.useState(false)
  const [showWaitingMessage, setShowWaitingMessage] = React.useState(false)

  const [createOnboardingDocument] =
    useMutation<CreateOnboardingDocumentData, CreateOnboardingDocumentVars>(
      CREATE_ONBOARDING_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateOnboardingDocument] =
    useMutation<UpdateOnboardingDocumentData, UpdateOnboardingDocumentVars>(
      UPDATE_ONBOARDING_DOCUMENT_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          ...(uboId ? [ULTIMATE_BENEFICIAL_OWNERS_QUERY] : []),
          {
            query: ONBOARDING_DOCUMENTS_QUERY,
            variables: { uboId, documentType: 'ADDRESS_PROOF' },
          },
        ],
      })

  const uploadFile = async (values: FormValues) => {
    if (!values.file) {
      setFormError(formRef, 'No se encontró el archivo de origen.')
      return false
    }

    const createResponse = await createOnboardingDocument({
      variables: {
        uboId,
        contentType: values.file.type,
        description: values.description,
        documentType: 'ADDRESS_PROOF',
      },
    })

    const proof = createResponse.data?.createOnboardingDocument

    if (!proof) {
      setFormError(formRef, translateError(createResponse))
      return false
    }

    const storageResponse = await uploadToStorage(proof.storagePost, values.file)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir tu archivo.')
      return false
    }

    const updateResponse = await updateOnboardingDocument({
      variables: {
        id: proof?.id || '',
        uboId,
      },
    })

    if (updateResponse.data?.updateOnboardingDocument === 'OK!') {
      return true
    }

    setFormError(formRef, translateError(updateResponse))
    return false
  }

  const handleSubmit = async (values: FormValues) => {
    setShowWaitingMessage(true)

    const response = await uploadFile(values)

    if (response) {
      handleNext()
    } else {
      setShowWaitingMessage(false)
    }
  }

  return (
    <React.Fragment>
      <IconCard
        elevation={0}
        icon={<Info />}
        color={darken(theme.palette.info.light, 0.6)}
        bgcolor={lighten(theme.palette.info.light, 0.8)}
        sx={{ border: 1, borderColor: 'info.dark' }}
      >
        Agrega un documento con fecha de emisión menor a 3 meses para justificar la siguiente dirección:
        <br />
        <strong>{address}</strong>
      </IconCard>
      {(addressProof && !showForm) ? (
        <AddressProofDisplay
          handleShowForm={() => setShowForm(true)}
          addressProof={addressProof}
          handleNext={handleNext}
        />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <StepForm
              {...props}
              isBusiness={isBusiness}
              showWaitingMessage={showWaitingMessage}
            />
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}
