import { gql } from '@apollo/client'

type BusinessOrigin = 'COMPANIES_OR_PARTNERSHIPS_EQUITY'
  | 'COMMERCIAL_OR_INDUSTRIAL_PROPERTIES'
  | 'FOREIGN_INVESTMENTS'
  | 'BUSINESS_ASSETS_SALE'
  | 'OTHER'

type PersonOrigin = 'EMPLOYMENT'
  | 'INHERITANCES'
  | 'LONG_TERM_INVESTMENTS'
  | 'REAL_ESTATE_PROPERTY'
  | 'BUSINESS_EQUITY'
  | 'ASSETS_SALE'
  | 'OTHER'

type Origin = BusinessOrigin | PersonOrigin

type OriginLabel = {
  type: Origin
  name: string
  label: string
}

export const BUSINESS_WEALTH_ORIGIN_LABELS: OriginLabel[] = [
  {
    type: 'COMPANIES_OR_PARTNERSHIPS_EQUITY',
    name: 'Participaciones en Empresas o Sociedades',
    label: 'Ingresos obtenidos de la propiedad o participación en empresas o sociedades',
  },
  {
    type: 'COMMERCIAL_OR_INDUSTRIAL_PROPERTIES',
    name: 'Propiedades Comerciales o Industriales',
    label: 'Ingresos obtenidos de la renta o venta de propiedades comerciales o industriales',
  },
  {
    type: 'FOREIGN_INVESTMENTS',
    name: 'Inversiones en el Extranjero',
    label: 'Ingresos generados por inversiones fuera del país',
  },
  {
    type: 'BUSINESS_ASSETS_SALE',
    name: 'Venta de Activos Empresariales',
    label: 'Ingresos obtenidos por la venta de activos empresariales',
  },
  {
    type: 'OTHER',
    name: 'Otros',
    label: 'Especifique cualquier otra fuente de patrimonio',
  },
]

export const PERSON_WEALTH_ORIGIN_LABELS: OriginLabel[] = [
  {
    type: 'EMPLOYMENT',
    name: 'Empleo',
    label: 'Ingresos regulares provenientes de un empleo',
  },
  {
    type: 'INHERITANCES',
    name: 'Herencia',
    label: 'Dinero o propiedades recibidas como parte de una herencia',
  },
  {
    type: 'LONG_TERM_INVESTMENTS',
    name: 'Inversiones a Largo Plazo',
    label: 'Ingresos derivados de inversiones a largo plazo',
  },
  {
    type: 'REAL_ESTATE_PROPERTY',
    name: 'Propiedades Inmobiliarias',
    label: 'Ingresos obtenidos de la renta o venta de propiedades inmobiliarias',
  },
  {
    type: 'BUSINESS_EQUITY',
    name: 'Participaciones Empresariales',
    label: 'Ingresos obtenidos de las participaciones en negocios o empresas',
  },
  {
    type: 'ASSETS_SALE',
    name: 'Venta de Activos',
    label: 'Ingresos obtenidos por la venta de activos personales',
  },
  {
    type: 'OTHER',
    name: 'Otros orígenes de patrimonio',
    label: 'Especifique cualquier otra fuente de patrimonio',
  },
]

export const WEALTH_ORIGIN_LABELS =
  BUSINESS_WEALTH_ORIGIN_LABELS.concat(PERSON_WEALTH_ORIGIN_LABELS)

export type WealthOrigin = {
  id: string
  descriptions: string[]
  origins: Origin[]
}

export const WEALTH_ORIGIN_FIELDS = gql`
  fragment WealthOriginFields on WealthOrigin {
    id
    descriptions
    origins
  }
`

export type WealthOriginVars = {
  uboId?: string
}

export type WealthOriginData = {
  wealthOrigin: WealthOrigin
}

export const WEALTH_ORIGIN_QUERY = gql`
  ${WEALTH_ORIGIN_FIELDS}
  query WealthOrigin (
    $uboId: ID
   ) {
    wealthOrigin(
      uboId: $uboId
    ) {
      ...WealthOriginFields
    }
  }
`

export type UpdateWealthOriginVars = {
  uboId?: string
  descriptions: string[]
  origins: string[]
}

export type UpdateWealthOriginData = {
  updateWealthOrigin: string
}

export const UPDATE_WEALTH_ORIGIN_MUTATION = gql`
  mutation UpdateWealthOrigin(
    $uboId: ID
    $descriptions: [String!]!
    $origins: [String!]!
  ) {
    updateWealthOrigin(
      input: {
        uboId: $uboId
        descriptions: $descriptions
        origins: $origins
      }
    )
  }
`
