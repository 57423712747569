import { gql } from '@apollo/client'

import { STORAGE_POST_FIELDS } from './storage_post'

import type { StoragePost } from './storage_post'

export type PoiContentType = 'image/heic' | 'image/jpeg' | 'image/jpg' | 'image/png'

export const POI_CONTENT_TYPES = ['image/heic', 'image/jpeg', 'image/jpg', 'image/png']

export type PoiDocumentType = 'ID_CARD_FRONT' | 'ID_CARD_BACK' | 'PASSPORT'

export const POI_DOCUMENT_TYPE_LABELS: { [key in PoiDocumentType]: string } = {
  'ID_CARD_FRONT': 'Carnet de identidad (frente)',
  'ID_CARD_BACK': 'Carnet de identidad (reverso)',
  'PASSPORT': 'Pasaporte',
}

export type IdentityProof = {
  id: string
  contentType: string
  documentType: PoiDocumentType
  expirationDate: string
  storagePost: StoragePost
}

export const IDENTITY_PROOF_FIELDS = gql`
  ${STORAGE_POST_FIELDS}
  fragment IdentityProofFields on IdentityProof {
    id
    contentType
    documentType
    expirationDate
    storagePost {
      ...StoragePostFields
    }
  }
`

export type CreateIdentityProofsVars = {
  uboId?: string
  contentTypeBack?: string
  contentTypeFront: string
  documentType: string
  expirationDate: string
}

export type CreateIdentityProofsData = {
  createIdentityProofs: IdentityProof[]
}

export const CREATE_IDENTITY_PROOFS_MUTATION = gql`
  ${IDENTITY_PROOF_FIELDS}
  mutation CreateIdentityProofs(
    $uboId: ID
    $contentTypeBack: String
    $contentTypeFront: String!
    $documentType: String!
    $expirationDate: ISO8601Date!
  ) {
    createIdentityProofs(
      input: {
        uboId: $uboId
        contentTypeBack: $contentTypeBack
        contentTypeFront: $contentTypeFront
        documentType: $documentType
        expirationDate: $expirationDate
      }
    ) {
      ...IdentityProofFields
    }
  }
`

export type UpdateIdentityProofVars = {
  id: string
  uboId?: string
}

export type UpdateIdentityProofData = {
  updateIdentityProof: string
}

export const UPDATE_IDENTITY_PROOF_MUTATION = gql`
  mutation UpdateIdentityProof(
    $id: ID!
    $uboId: ID
  ) {
    updateIdentityProof(
      input: {
        id: $id
        uboId: $uboId
      }
    )
  }
`

export type IdentityProofView = {
  id: string
  contentType: PoiContentType
  documentType: PoiDocumentType
  expirationDate: string
  storageUrl?: string
}

export const IDENTITY_PROOF_VIEW_FIELDS = gql`
  fragment IdentityProofViewFields on IdentityProofView {
    id
    contentType
    documentType
    expirationDate
    storageUrl
  }
`

export type IdentityProofsVars = {
  uboId?: string
}

export type IdentityProofsData = {
  identityProofs: IdentityProofView[]
}

export const IDENTITY_PROOFS_QUERY = gql`
  ${IDENTITY_PROOF_VIEW_FIELDS}
  query IdentityProofs (
    $uboId: ID
   ) {
    identityProofs(
      uboId: $uboId
    ) {
      ...IdentityProofViewFields
    }
  }
`

export type RenewIdentityProofsVars = {
  uboId?: string
  contentTypeBack?: string
  contentTypeFront: string
  documentType: string
  expirationDate: string
}

export type RenewIdentityProofsData = {
  renewIdentityProofs: IdentityProof[]
}

export const RENEW_IDENTITY_PROOFS_MUTATION = gql`
  ${IDENTITY_PROOF_FIELDS}
  mutation RenewIdentityProofs(
    $uboId: ID
    $contentTypeBack: String
    $contentTypeFront: String!
    $documentType: String!
    $expirationDate: ISO8601Date!
  ) {
    renewIdentityProofs(
      input: {
        uboId: $uboId
        contentTypeBack: $contentTypeBack
        contentTypeFront: $contentTypeFront
        documentType: $documentType
        expirationDate: $expirationDate
      }
    ) {
      ...IdentityProofFields
    }
  }
`
