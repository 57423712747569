import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button, InputAdornment, MenuItem, Stack, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select } from 'formik-mui'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  ErrorDisplay,
  Loading,
  NotificationConfirmation,
} from '../components'
import { CurrencyField } from '../forms'
import {
  FUNDS_QUERY,
  NOTIFY_USER_REQUEST_MUTATION,
} from '../queries'
import { setFormError, translateError } from '../services'

import type {
  Fund,
  FundsData,
  FundsVars,
  NotifyUserRequestData,
  NotifyUserRequestVars,
} from '../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  name: string
  amount: number
  useMaxAmount: boolean
}

const initialValues =  ({
  name: '',
  amount: 0,
  useMaxAmount: false,
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero'),
    name: Yup.string()
      .required('Este campo es obligatorio'),
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
  })

type InnerFormProps = FormikProps<FormValues> & {
  funds: Fund[]
}

const InnerForm = ({
  isSubmitting,
  isValid,
  submitForm,
  status,
  setFieldValue,
  setFieldTouched,
  values,
  funds,
}: InnerFormProps) => {
  const fund = funds.find((fund) => fund.name === values.name)

  const onUseMaxAmountChange = () => {
    if (!fund) {
      return
    }

    const newUseMaxAmount = !values.useMaxAmount
    setFieldValue('useMaxAmount', newUseMaxAmount)
    setFieldValue('amount', newUseMaxAmount ? fund.balance : 0)
    setFieldTouched('amount', false, false)
  }

  return (
    <Form
      onSubmit={(event) => {
        event?.preventDefault()
        submitForm()
      }}
    >
      <Stack
        spacing={3}
        marginTop={2}
      >
        <Field
          required
          name='name'
          type='text'
          label='Selecciona un fondo'
          component={Select}
          formControl={{ sx: { width: '100%' } }}
        >
          {funds.map((fund, index) => (
            <MenuItem
              key={index}
              value={fund.name}
            >
              {fund.name}
            </MenuItem>
          ))}
        </Field>
        <CurrencyField
          name='amount'
          label='Monto a vender'
          disabled={!values.name || values.useMaxAmount}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>US</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          digits={2}
          positive
        />
        <Field
          required
          component={CheckboxWithLabel}
          disabled={!fund}
          onChange={onUseMaxAmountChange}
          type='checkbox'
          name='useMaxAmount'
          Label={{
            sx: { mt: -1, mb: 1 },
            label: (
              <React.Fragment>
                Usar el máximo disponible (aprox.&nbsp;
                <Currency
                  currency='USD'
                  value={fund?.balance || 0}
                />)
              </React.Fragment>
            ),
          }}
        />
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            type='submit'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

export const FundSaleForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [message, setMessage] = React.useState<string[]>([])

  const { loading: fundsLoading, data: fundsData } =
    useQuery<FundsData, FundsVars>(FUNDS_QUERY)

  const [notifyUserRequest] =
    useMutation<NotifyUserRequestData, NotifyUserRequestVars>(
      NOTIFY_USER_REQUEST_MUTATION, {
        errorPolicy: 'all',
      })

  if (fundsLoading) {
    return <Loading />
  }

  const handleSubmit = async (values: FormValues) => {
    const response = await notifyUserRequest({
      variables: {
        notificationType: 'FUND_SALE',
        content: ['US$', values.amount.toString(), values.name],
      },
    })

    if (response.data?.notifyUserRequest) {
      setMessage(response.data.notifyUserRequest)
      setShowConfirmation(true)
      return
    }

    setFormError(formRef, translateError(response))
  }

  return showConfirmation ? (
    <NotificationConfirmation
      message={message}
      isPurchase
    />
  ) : (
    <React.Fragment>
      <Typography
        variant='h6'
        component='span'
        textAlign='center'
        gutterBottom
      >
        Vender cuotas de fondo
      </Typography>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            funds={fundsData?.funds.funds || []}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}
