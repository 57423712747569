import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Warning } from '@mui/icons-material'
import { Button, Divider, Stack, darken, lighten, useTheme } from '@mui/material'

import { AddressProofForm } from './address_proof_form'
import { BusinessAddressProofDisplay } from './business_address_proof_display'
import { UboAddressProofDisplay } from './ubo_address_proof_display'
import { IconCard, Loading } from '../../../components'
import {
  LEGAL_ADDRESS_QUERY,
  ONBOARDING_DOCUMENTS_QUERY,
  ULTIMATE_BENEFICIAL_OWNERS_QUERY,
} from '../../../queries'
import { addressToString, getLastAddressProof, hasDocument } from '../../../services'

import type {
  LegalAddressData,
  LegalAddressVars,
  OnboardingDocumentView,
  OnboardingDocumentsData,
  OnboardingDocumentsVars,
  UltimateBeneficialOwnersData,
  UltimateBeneficialOwnersVars,
} from '../../../queries'

type BusinessAddressProofProps = {
  addressProof?: OnboardingDocumentView
  address: string
  handleNext: () => void
}

const BusinessAddressProof = ({
  addressProof,
  address,
  handleNext,
}: BusinessAddressProofProps) => {
  const theme = useTheme()

  const { loading, data } =
    useQuery<UltimateBeneficialOwnersData, UltimateBeneficialOwnersVars>(
      ULTIMATE_BENEFICIAL_OWNERS_QUERY,
    )

  const ubos = data?.ultimateBeneficialOwners || []

  const everyUboHasAddressProof =
    ubos.every((ubo) => hasDocument(ubo.onboardingDocuments, 'ADDRESS_PROOF'))
  const everyAddressProofExists = addressProof && everyUboHasAddressProof

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <BusinessAddressProofDisplay
        addressProof={addressProof}
        address={address}
      />
      <Divider>Beneficiarios finales</Divider>
      {ubos.map((ultimateBeneficialOwner, index) => (
        <UboAddressProofDisplay
          key={index}
          ubo={ultimateBeneficialOwner}
        />
      ))}
      {!everyAddressProofExists && (
        <IconCard
          elevation={0}
          icon={<Warning />}
          color={darken(theme.palette.warning.light, 0.6)}
          bgcolor={lighten(theme.palette.warning.light, 0.8)}
          sx={{ border: 1, borderColor: 'warning.dark' }}
        >
          Agrega todas las pruebas de direcciones para completar este paso
        </IconCard>
      )}
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        pt={2}
      >
        <Button
          fullWidth
          disabled={!everyAddressProofExists}
          onClick={handleNext}
          variant='contained'
        >
          Siguiente
        </Button>
      </Stack>
    </React.Fragment>
  )
}

type AddressProofStepProps = {
  isBusiness: boolean
  handleNext: () => void
}

export const AddressProofStep = ({
  isBusiness,
  handleNext,
}: AddressProofStepProps) => {
  const { loading: docLoading, data: docData } =
    useQuery<OnboardingDocumentsData, OnboardingDocumentsVars>(
      ONBOARDING_DOCUMENTS_QUERY, {
        variables: {
          documentType: 'ADDRESS_PROOF',
        },
      },
    )

  const { loading: addressLoading, data: addressData } =
    useQuery<LegalAddressData, LegalAddressVars>(LEGAL_ADDRESS_QUERY)

  if (docLoading || addressLoading) {
    <Loading />
  }

  const addressProof = getLastAddressProof(docData?.onboardingDocuments || [])
  const address = addressToString(addressData?.legalAddress)

  return isBusiness ? (
    <BusinessAddressProof
      addressProof={addressProof}
      address={address}
      handleNext={handleNext}
    />
  ) : (
    <AddressProofForm
      isBusiness={false}
      addressProof={addressProof}
      address={address}
      handleNext={handleNext}
    />
  )
}
