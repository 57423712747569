import { gql } from '@apollo/client'

import { FUNDS_ORIGIN_FIELDS, FundsOrigin } from './funds_origins'
import { IDENTITY_PROOF_VIEW_FIELDS, IdentityProofView } from './identity_proofs'
import { ONBOARDING_DOCUMENT_VIEW_FIELDS, OnboardingDocumentView } from './onboarding_documents'
import { WEALTH_ORIGIN_FIELDS, WealthOrigin } from './wealth_origins'

type OwnershipRole = 'ULTIMATE_BENEFICIARY' | 'EFFECTIVE_CONTROL'

export const OWNERSHIP_ROLE_LABELS: { [key in OwnershipRole]: string } = {
  ULTIMATE_BENEFICIARY: 'Beneficiario final',
  EFFECTIVE_CONTROL: 'Control efectivo',
}

type TopManagementRole = 'CEO'
  | 'CFO'
  | 'COO'
  | 'CTO'
  | 'CIO'
  | 'CHRO'
  | 'CMO'
  | 'CSO'
  | 'CLO'
  | 'CRO'

export const TOP_MANAGEMENT_ROLE_LABELS: { [key in TopManagementRole]: string } = {
  CEO: 'Gerente general (CEO)',
  CFO: 'Director financiero (CFO)',
  COO: 'Director de operaciones (COO)',
  CTO: 'Director de tecnología (CTO)',
  CIO: 'Director de información (CIO)',
  CHRO: 'Director de recursos humanos (CHRO)',
  CMO: 'Director de marketing (CMO)',
  CSO: 'Director comercial (CSO)',
  CLO: 'Director legal (CLO)',
  CRO: 'Director de riesgos (CRO)',
}

export type Role = 'LEGAL_REPRESENTATIVE' | OwnershipRole | TopManagementRole

export const ROLE_LABELS: { [key in Role]: string } = {
  LEGAL_REPRESENTATIVE: 'Representante legal',
  ...OWNERSHIP_ROLE_LABELS,
  ...TOP_MANAGEMENT_ROLE_LABELS,
}

export type UltimateBeneficialOwner = {
  city: string
  countryCode: string
  familyName: string
  givenName: string
  id: string
  identificationNumber: string
  isChileanResident: boolean
  isPoliticallyExposed: boolean
  nationality: string
  participationPercentage: number
  roles: Role[]
  streetAddress: string
  identityProofs: IdentityProofView[]
  onboardingDocuments: OnboardingDocumentView[]
  fundsOrigin?: FundsOrigin
  wealthOrigin?: WealthOrigin
}

export const ULTIMATE_BENEFICIAL_OWNER_FIELDS = gql`
  ${FUNDS_ORIGIN_FIELDS}
  ${WEALTH_ORIGIN_FIELDS}
  ${IDENTITY_PROOF_VIEW_FIELDS}
  ${ONBOARDING_DOCUMENT_VIEW_FIELDS}
  fragment UltimateBeneficialOwnerFields on UltimateBeneficialOwner {
    city
    countryCode
    familyName
    givenName
    id
    identificationNumber
    isChileanResident
    isPoliticallyExposed
    nationality
    participationPercentage
    roles
    streetAddress
    identityProofs {
      ...IdentityProofViewFields
    }
    onboardingDocuments {
      ...OnboardingDocumentViewFields
    }
    fundsOrigin {
      ...FundsOriginFields
    }
    wealthOrigin {
      ...WealthOriginFields
    }
  }
`

export type UltimateBeneficialOwnersVars = Record<string, never>

export type UltimateBeneficialOwnersData = {
  ultimateBeneficialOwners: UltimateBeneficialOwner[]
}

export const ULTIMATE_BENEFICIAL_OWNERS_QUERY = gql`
  ${ULTIMATE_BENEFICIAL_OWNER_FIELDS}
  query UltimateBeneficialOwners {
    ultimateBeneficialOwners {
      ...UltimateBeneficialOwnerFields
    }
  }
`

export type CreateUltimateBeneficialOwnerVars = {
  city: string
  countryCode: string
  familyName: string
  givenName: string
  identificationNumber: string
  isChileanResident: boolean
  isPoliticallyExposed: boolean
  nationality: string
  participationPercentage?: number
  roles: string[]
  streetAddress: string
}

export type CreateUltimateBeneficialOwnerData = {
  createUltimateBeneficialOwner: string
}

export const CREATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION = gql`
  mutation CreateUltimateBeneficialOwner(
    $city: String!
    $countryCode: String!
    $familyName: String!
    $givenName: String!
    $identificationNumber: String!
    $isChileanResident: Boolean!
    $isPoliticallyExposed: Boolean!
    $nationality: String!
    $participationPercentage: Float
    $roles: [String!]!
    $streetAddress: String!
  ) {
    createUltimateBeneficialOwner(
      input: {
        city: $city
        countryCode: $countryCode
        familyName: $familyName
        givenName: $givenName
        identificationNumber: $identificationNumber
        isChileanResident: $isChileanResident
        isPoliticallyExposed: $isPoliticallyExposed
        nationality: $nationality
        participationPercentage: $participationPercentage
        roles: $roles
        streetAddress: $streetAddress
      }
    )
  }
`

export type DeleteUltimateBeneficialOwnerVars = {
  ultimateBeneficialOwnerId: string
}

export type DeleteUltimateBeneficialOwnerData = {
  deleteUltimateBeneficialOwner: string
}

export const DELETE_ULTIMATE_BENEFICIAL_OWNER_MUTATION = gql`
  mutation DeleteUltimateBeneficialOwner(
    $ultimateBeneficialOwnerId: ID!
  ) {
    deleteUltimateBeneficialOwner(
      input: {
        ultimateBeneficialOwnerId: $ultimateBeneficialOwnerId
      }
    )
  }
`

export type UpdateUltimateBeneficialOwnerVars = {
  id: string
  city: string
  countryCode: string
  familyName: string
  givenName: string
  identificationNumber: string
  isChileanResident: boolean
  isPoliticallyExposed: boolean
  nationality: string
  participationPercentage?: number
  roles: string[]
  streetAddress: string
}

export type UpdateUltimateBeneficialOwnerData = {
  updateUltimateBeneficialOwner: string
}

export const UPDATE_ULTIMATE_BENEFICIAL_OWNER_MUTATION = gql`
  mutation UpdateUltimateBeneficialOwner(
    $id: ID!
    $city: String!
    $countryCode: String!
    $familyName: String!
    $givenName: String!
    $identificationNumber: String!
    $isChileanResident: Boolean!
    $isPoliticallyExposed: Boolean!
    $nationality: String!
    $participationPercentage: Float
    $roles: [String!]!
    $streetAddress: String!
  ) {
    updateUltimateBeneficialOwner(
      input: {
        id: $id
        city: $city
        countryCode: $countryCode
        familyName: $familyName
        givenName: $givenName
        identificationNumber: $identificationNumber
        isChileanResident: $isChileanResident
        isPoliticallyExposed: $isPoliticallyExposed
        nationality: $nationality
        participationPercentage: $participationPercentage
        roles: $roles
        streetAddress: $streetAddress
      }
    )
  }
`
