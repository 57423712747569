import * as React from 'react'

import { useMutation } from '@apollo/client'

import { OccupationProfileStep } from './occupation_profile_step'
import { PersonIdentityStep } from './person_identity_step'
import { ResponsiveStepper } from '../../../components'
import { UPDATE_VERIFICATION_MUTATION } from '../../../queries'
import { IdentityProofsStep } from '../identity_proofs_step'
import { LegalAddressStep } from '../legal_address_step'
import { LivenessProofStep } from '../liveness_proof_step'
import { PendingRevisionStep } from '../pending_revision_step'
import { TransactionProfileStep } from '../transaction_profile_step'

import type {
  UpdateVerificationData,
  UpdateVerificationVars,
} from '../../../queries'

const STEP_LABELS = [
  'Información básica',
  'Verificación de identidad',
  'Dirección',
  'Datos laborales',
  'Perfil transaccional',
  'Reconocimiento facial',
]

export const OnboardingFormKYC = () => {
  const [activeStep, setActiveStep] = React.useState(0)

  const [updateVerification] =
    useMutation<UpdateVerificationData, UpdateVerificationVars>(UPDATE_VERIFICATION_MUTATION, {
      errorPolicy: 'all',
    })

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const isOnConfirmationStep = (activeStep === STEP_LABELS.length)

  React.useEffect(() => {
    if (isOnConfirmationStep) {
      updateVerification()
    }
  }, [isOnConfirmationStep, updateVerification])

  return (
    <React.Fragment>
      <ResponsiveStepper
        activeStep={activeStep}
        stepLabels={STEP_LABELS}
      >
        {(activeStep) => {
          switch (activeStep) {
          case 0: return (
            <PersonIdentityStep
              handleNext={handleNext}
            />
          )
          case 1: return (
            <IdentityProofsStep
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 2: return (
            <LegalAddressStep
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 3: return (
            <OccupationProfileStep
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 4: return (
            <TransactionProfileStep
              isBusiness={false}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          )
          case 5: return (
            <LivenessProofStep
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          }
        }}
      </ResponsiveStepper>
      {(isOnConfirmationStep) && (
        <PendingRevisionStep />
      )}
    </React.Fragment>
  )
}
