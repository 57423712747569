import { OnboardingDocumentType, OnboardingDocumentView } from '../queries'

export const getLastAddressProof = (onboardingDocuments: OnboardingDocumentView[]) => (
  onboardingDocuments
    .slice()
    .sort((a, b) => b.createdAt - a.createdAt)
    .find((doc) => doc.documentType === 'ADDRESS_PROOF')
)

export const withDocumentType =
  (documents: OnboardingDocumentView[], documentType: OnboardingDocumentType) =>
    documents.filter((doc) => doc.documentType === documentType)

export const hasDocument =
  (documents: OnboardingDocumentView[], documentType: OnboardingDocumentType) =>
    withDocumentType(documents, documentType).length > 0
