import * as React from 'react'

import { WealthOriginProofsCreator } from './wealth_origin_proofs_creator'
import { Dialog } from '../../../components'
import { withDocumentType } from '../../../services'
import { IdentityDisplay } from '../identity_display'

import type { UltimateBeneficialOwner } from '../../../queries'

type UboWealthOriginProofsDisplayProps = {
  ubo: UltimateBeneficialOwner
}

export const UboWealthOriginProofsDisplay = ({
  ubo,
}: UboWealthOriginProofsDisplayProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const wealthOriginProofs = withDocumentType(ubo.onboardingDocuments, 'WEALTH_ORIGIN_PROOF')

  return (
    <React.Fragment>
      <IdentityDisplay
        type='PERSON'
        name={`${ubo.givenName} ${ubo.familyName}`}
        isValid={wealthOriginProofs.length > 0}
        openDialog={openDialog}
      />
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title={`Prueba de origen de patrimonio para ${ubo.givenName} ${ubo.familyName}`}
      >
        <WealthOriginProofsCreator
          uboId={ubo.id}
          wealthOriginProofs={wealthOriginProofs}
          isBusiness={false}
          handleBack={closeDialog}
          handleNext={closeDialog}
        />
      </Dialog>
    </React.Fragment>
  )
}
